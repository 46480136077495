import React, { useRef, useEffect } from "react";
import "./OTPInput.scss";

const OtpInput = ({ value, onChange }) => {
  const inputRefs = useRef(new Array(6).fill(null));

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...value];
    newOtp[index] = element.value;
    onChange(newOtp);

    if (index < 5 && element.value) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleBackspace = (event, index) => {
    if (event.key === "Backspace" && !value[index]) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (
    <div className="otp-container">
      {value?.map((data, index) => (
        <React.Fragment key={index}>
          <input
            ref={(el) => (inputRefs.current[index] = el)}
            className="otp-input plt-body-lg input-field-custom"
            type="text"
            name="otp"
            maxLength="1"
            placeholder="-"
            value={data}
            onChange={(e) => handleChange(e.target, index)}
            onKeyDown={(e) => handleBackspace(e, index)}
            onFocus={(e) => e.target.select()}
          />
          {index === 2 && (
            <div className="line-after-three-inputs plt-body-lg">-</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default OtpInput;
