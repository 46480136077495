import React, { useState } from "react";
import "./ElevationStyles.scss";
import TabNavigation from "components/DesignSystemPage/TabNavigation/index";
import AddButton from "components/Buttons/AddButton";
import CommonModal from "components/Common/CommonModal";
import ColorPickerComponent from "components/DesignSystemPage/ColorPickerComponent";
import {
  neutralColorShade,
  primaryColorShade,
  secondaryColorShade,
  supportingColorShade1,
  supportingColorShade2,
} from "assets/data/tokensDefaultData";
import CustomSelect from "components/Common/CustomSelect";
import EditButton from "components/Buttons/EditButton";
import ElevationCard from "components/Elevation/ElevationCard";
import AuthAction from "components/auth/AuthAction";
import useScrollManagement from "hooks/useScrollManagement";

const ElevationStyles = ({ datas }) => {
  const [selectedFont, setSelectedFont] = useState("Light");
  const [selectedEffect, setSelectedEffect] = useState("Drop shadow");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isColorTokenModalOpen, setColorTokenModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [isStyleScaleModalOpen, setStyleScaleModalOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [elevation, setElevation] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });

  const [colortoken, setColortoken] = useState({
    name: "heading-3xl",
    description: "",
    weight: "Regular",
    size: "16",
    lineHeight: "130%",
    letterSpacing: "0px",
    paragraphSpacing: "15px",
  });

  const fonts = ["Light", "Medium", "Intense"];
  const effects = ["Drop shadow", "Inner shadow"];
  const tabData = ["Principles", "Major styles"];
  const maxChar = 20;

  const handleFontChange = (value) => setSelectedFont(value);

  const handleEffectChange = (value) => setSelectedEffect(value);

  const openModal = () => {
    setModalOpen(true);
    setConfirmModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setConfirmModalOpen(false);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
    scrollToSection(index);
  };

  const sectionRefs = React.useRef([React.createRef(), React.createRef()]);

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeTab,
    setActiveTab,
    "tab-content-body"
  );

  const handleInputChange = (field, value) => {
    if (field === "description" && value.length > maxChar) {
      return;
    }
    setElevation({ ...elevation, [field]: value });
    if (field === "description") {
      setCharCount(value.length);
    }
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        <div className='plt-content-block dis-mb-0'>
          {/* <div className='plt-dropdown-container dis-pb-32'>
            <div className='plt-value-wrapper-top d-flex flex-column'>
              <div className='plt-value-wrapper d-flex align-items-center dis-mb-16'>
                <h6 className='plt-value-title dis-pr-8 plt-heading-md plt-font-color-primary'>
                  Style set:
                </h6>
                <h4 className='plt-value-txt plt-heading-xs font-weight-600 plt-font-color-primary'>
                  Light
                </h4>
              </div>
            </div>
            <AuthAction>
              <span
                onClick={() => setStyleScaleModalOpen(true)}
                className='plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block'
              >
                Change style set
              </span>
            </AuthAction>
          </div> */}
          <div className='plt-content-wrapper dis-mb-48'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
              <h6
                ref={sectionRefs.current[0]}
                className='plt-contents-head plt-heading-md plt-font-color-primary'
              >
                Principles
              </h6>
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Elevations are the layered surfaces that form the foundation of
              the UI. They create a blank canvas where other UI will be placed,
              such as text, icons, backgrounds, and borders.
            </p>
          </div>
        </div>
        <div className='plt-content-block dis-mb-0'>
          <div className='plt-content-wrapper dis-mb-24'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
              <h6
                ref={sectionRefs.current[1]}
                className='plt-contents-head plt-heading-md plt-font-color-primary'
              >
                Major styles
              </h6>
              {/* <AuthAction>
                <span
                  onClick={() => setModalOpen(true)}
                  className='plt-function-icon'
                >
                  <AddButton />
                </span>
              </AuthAction> */}
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Elevations contribute to the visual hierarchy and spatial
              relationships between components.
            </p>
          </div>
        </div>

        <div className='plt-common-card-wrapper d-flex'>
          {datas.map((data) => (
            <div key={data?.id} className='plt-common-card'>
              <ElevationCard elevationBoxData={data} />
            </div>
          ))}
        </div>
      </div>
      <div className='plt-token-contents '>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>

      <CommonModal
        // isOpen={isAddElevationModalOpen}
        // onRequestClose={() => setAddElevationModalOpen(false)}
        // title="Add elevation style"
        // onApply={() => setAddElevationModalOpen(false)}
        // onCancelText="Cancel"
        // onApplyText="Apply"

        isOpen={isModalOpen}
        onRequestClose={() => closeModal(false)}
        title='Add elevation style'
        onApply={() => openModal(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-dropdown-secondary '>
            <div className='plt-select-dropdown-wrapper d-flex align-items-center justify-content-between'>
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-shadow'>
                <CustomSelect
                  options={effects}
                  selectedOption={selectedEffect}
                  onSelect={handleEffectChange}
                />
              </div>
            </div>
          </div>

          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div className='plt-common-card-img-box m-auto'></div>
          </div>

          <div className='modal-value-wrapper dis-mb-32'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Style name
            </h6>
            <div className='modal-value-block w-100'>
              <p className='plt-badge plt-token-value-name plt-body-compact-sm'>
                shadow-300
              </p>
            </div>
          </div>
          <div className='elevation-modal-detail d-flex align-items-center justify-content-between dis-mb-32'>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                X
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value=''
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Y
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value=''
                />
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Blur
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value=''
                />
                <span className='input-suffix plt-body-compact-sm plt-font-color-tertiary'>
                  %
                </span>
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Spread
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  value=''
                />
              </div>
            </div>
          </div>
          <div className='modal-value-wrapper dis-mb-32'>
            <h6 className='modal-value-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Select color
            </h6>
            <div className='plt-token-value-item d-flex'>
              <p
                className='plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12 cursor-pointer'
                onClick={() => setColorTokenModalOpen(true)}
              >
                secondary-blue-30
              </p>
              <span className='plt-iconbtn plt-iconbtn-sm plt-token-value-icon'>
                <EditButton />
              </span>
            </div>
          </div>

          <div className='modal-textarea-wrapper dis-mb-24'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular dis-mb-4'>
              Add description
            </h6>
            <textarea
              className='modal-textarea w-100'
              placeholder='Text'
              value={elevation.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setConfirmModalOpen(false)}
        title='Change elevation style?'
        onApply={() => setConfirmModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <p className='plt-body-md plt-font-color-primary'>
            This change will be reflected in the entire system.
          </p>
        </div>
      </CommonModal>

      <CommonModal
        isOpen={isStyleScaleModalOpen}
        onRequestClose={() => setStyleScaleModalOpen(false)}
        title='Change style set'
        onApply={() => setStyleScaleModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
        // variant="modal-secondary"
      >
        <div className='plt-modal-body-content'>
          <div className='plt-select-dropdown-wrapper dis-pl-24 dis-pr-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
              Style set
            </h6>
            <div className='plt-dropdown-wrapper plt-dropdown-wrapper-style-set'>
              <CustomSelect
                options={fonts}
                selectedOption={selectedFont}
                onSelect={handleFontChange}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default ElevationStyles;
