import React from "react";
import alertguidelines1 from "../../../assets/images/png/alertguidelines1.png";
import alertguidelines2 from "../../../assets/images/png/alertguidelines2.png";


const ComponentPrimaryDatas = [
  {
    id: 1,
    title: "Do",
    titleClassname: "active",
    image: alertguidelines1,
    description:
      "Place labels to the left of text input fields, ensuring they are aligned with the top of the field for clarity. Maintain consistent spacing between the label and input field.",
  },
  {
    id: 2,
    title: "Don’t",
    titleClassname: "inactive",
    image: alertguidelines2,
    description:
      "Avoid placing labels above or below the text field, and do not misalign labels to the left. Ensure labels are not too close or too far from the input fields.",
  },
];

const GuidelineCardAlert = () => {
  return (
    <div className='guideline-card d-flex justify-content-between'>
      {ComponentPrimaryDatas.map((data) => (
        <div key={data.id} className='guideline-wrapper'>
          <h6
            className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}
          >
            {data.title}
          </h6>
          <div className='guideline-detail-wrapper d-flex align-items-center justify-content-center'>
            <div className='guideline-img'>
              <img src={data.image} alt='userimage' />
            </div>
          </div>
          <p className='guideline-desc plt-body-md dis-mt-12'>
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GuidelineCardAlert;
