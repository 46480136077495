import React, { useState, useRef, useEffect } from "react";
import "./CustomSelect.scss"; // Import your stylesheet

const CustomSelect = ({
  options,
  selectedOption,
  onSelect,
  className,
  icon,
  isModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectedOption);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedValue(option);
    onSelect(option); // Pass the selected option to the callback
    setIsOpen(false); // This line closes the dropdown
  };

  // Clicking outside the dropdown should close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`custom-dropdown ${className} ${isModal ? "modal-dropdown" : ""}`}
      ref={dropdownRef}
    >
      <div
        className="dropdown-header plt-font-color-primary"
        onClick={toggleDropdown}
      >
        {selectedValue}
        <span className="plt-custom-select-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6668 5.6665L8.00016 10.3332L3.3335 5.6665"
              stroke="#171717"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option, index) => (
            <div
              key={index}
              className={`dropdown-item plt-ui-text-sm-medium plt-font-color-primary ${selectedValue === option ? "selected" : ""
                }`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
