import React from "react";
import ".//ComponentButtonCard.scss";
import PrimaryButton from "../../../components/Common/PrimaryButton";

const ComponentButtonCard = ({ state, buttonType }) => {
  return (
    <div className="plt-component-btn-card">
      <div className="plt-component-btn-wrapper d-flex align-items-center justify-content-center">
        <PrimaryButton
          // onClick={{}}
          text={"Button text"}
          className={`dss-btn dss-btn-${buttonType} dss-btn-lg ${
            state ? state : ""
          } dss-ui-text-button-text-lg pointer-none `}
        />
      </div>
    </div>
  );
};

export default ComponentButtonCard;
