import React from "react";

const fontSizeData = () => {
  return [
    {
      key: "core.heading.3xl",
      token: "--dss-heading-3xl-font-size",
      className: "dss-heading-3xl",
      fontSizeToken: "--dss-font-size-1000",
      fontSizeKey: "core.fontSize.1000",
    },
    {
      key: "core.heading.2xl",
      token: "--dss-heading-2xl-font-size",
      className: "dss-heading-2xl",
      fontSizeToken: "--dss-font-size-900",
      fontSizeKey: "core.fontSize.900",
    },
    {
      key: "core.heading.xl",
      token: "--dss-heading-xl-font-size",
      className: "dss-heading-xl",
      fontSizeToken: "--dss-font-size-800",
      fontSizeKey: "core.fontSize.800",
    },
    {
      key: "core.heading.lg",
      token: "--dss-heading-lg-font-size",
      className: "dss-heading-lg",
      fontSizeToken: "--dss-font-size-700",
      fontSizeKey: "core.fontSize.700",
    },
    {
      key: "core.heading.md",
      token: "--dss-heading-md-font-size",
      className: "dss-heading-md",
      fontSizeToken: "--dss-font-size-600",
      fontSizeKey: "core.fontSize.600",
    },
    {
      key: "core.heading.sm",
      token: "--dss-heading-sm-font-size",
      className: "dss-heading-sm",
      fontSizeToken: "--dss-font-size-500",
      fontSizeKey: "core.fontSize.500",
    },
    {
      key: "core.heading.xs",
      token: "--dss-heading-xs-font-size",
      className: "dss-heading-xs",
      fontSizeToken: "--dss-font-size-400",
      fontSizeKey: "core.fontSize.400",
    },
    {
      key: "core.heading.xxs",
      token: "--dss-heading-xxs-font-size",
      className: "dss-heading-xxs",
      fontSizeToken: "--dss-font-size-300",
      fontSizeKey: "core.fontSize.300",
    },
  ];
};

export default fontSizeData;
