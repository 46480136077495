import React, { useState, useRef, useEffect } from "react";
import SettingsLayout from "components/layouts/SettingsLayout";
import TeamTable from "./TeamTable";
import AddMemberModal from "./AddMemeberModal";
import { useGlobalContext } from "context/GlobalContext";
import { getUser } from "hooks/storageUtils";
import { addMember, fetchTeamMembers, fetchRoles } from "api";
import { FilterButtonicon, PlusButtonWhiteicon } from "components/Icons";
import "./Team.scss";
import Dropdown from "components/Common/Dropdown/Dropdown";

// import {  } from "components/Icons";

const Team = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [teams, setTeams] = useState();
  const [roles, setRoles] = useState([]);

  const { setLoading } = useGlobalContext();

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const getTeamMembers = async (userId) => {
      try {
        setLoading(true);
        const { data } = await fetchTeamMembers(userId);
        setTeams(data);
      } catch (error) {
        console.error("Failed to fetch team memeber", error);
      } finally {
        setLoading(false);
      }
    };

    const getRoles = async () => {
      try {
        setLoading(true);
        const data = await fetchRoles();
        setRoles(
          data?.data?.map((d) => {
            return { value: d.id, label: d.name };
          })
        );
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
      }
    };
    getRoles();

    const user = getUser();
    getTeamMembers(user.id);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleAddMember = async (request) => {
    try {
      setLoading(true);
      const user = getUser();
      const input = { data: request, admin_id: user.id };
      const { data } = await addMember(input);
      setTeams((teams) => [...teams, ...data]);
    } catch (error) {
      // toast.error(error.message);
      console.error("Failed to add member", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsLayout>
      <div className='tab-content-wrapper'>
        <div className='tab-content-container dis-pr-48 dis-pl-48'>
          {/* <div className='plt-content-block dis-mb-0 w-100'>
          <div className='plt-content-wrapper dis-mb-28'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center'>
              <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
              Teams
              </h6>
            </div>
          </div>
        </div> */}

          <div className={`plt-content-block dis-mb-32 w-100`}>
            <div className='plt-content-wrapper dis-mb-40 d-flex justify-content-between align-items-center'>
              <div className='plt-content-block-top'>
                {/* <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
              Members
              </h6> */}
                <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
                  Teams
                </h6>
              </div>
              <div className='plt-btn-wrapper d-flex'>
                {/* <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Description
            </p> */}
                <button className='plt-btn plt-btn-primary' onClick={openModal}>
                  <span className='dss-icon dis-mr-4'>
                    {" "}
                    <PlusButtonWhiteicon />
                  </span>
                  Add member
                </button>
                <div
                  className='plt-select-dropdown-wrapper plt-filter-dropdown'
                  ref={dropdownRef}
                >
                  <button
                    className='plt-btn plt-btn-secondary dis-ml-12'
                    onClick={() => setIsDropdownOpen((prev) => !prev)}
                  >
                    <span className='dss-icon dis-mr-4'>
                      {" "}
                      <FilterButtonicon />
                    </span>
                    Filters
                  </button>

                  <Dropdown
                    isOpen={isDropdownOpen}
                    onToggle={setIsDropdownOpen}
                    customClass=""
                    trigger={isDropdownOpen}
                  >
                    <ul>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Name
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Role
                      </li>
                      <li className='dropdown-item plt-ui-text-sm-medium plt-font-color-primary cursor-pointer'>
                        Access
                      </li>
                    </ul>
                  </Dropdown>

                </div>
              </div>
            </div>
            <TeamTable data={teams} roles={roles} setTeams={setTeams} />
          </div>

          {/* <div className='d-flex align-items-center justify-content-between dis-pt-32'>
            <div className='plt-modal-footer dis-p-0 ml-auto'>
              <button className='plt-btn plt-btn-secondary'>Cancel</button>
              <button className='plt-btn plt-btn-primary'>Update teams</button>
            </div>
          </div> */}
        </div>
      </div>
      <AddMemberModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        onApply={handleAddMember}
        title='Add members'
        roles={roles}
      />
    </SettingsLayout>
  );
};

export default Team;
