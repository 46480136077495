// src/index.js
import React from "react";
import { createRoot } from "react-dom/client";
import AppRouter from "./AppRouter";
import { GlobalProvider } from "./context/GlobalContext";
import "./assets/scss/main.scss";
import PusherComponent from "components/Pusher/Pusher";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <GlobalProvider>
    <AppRouter />
    <PusherComponent />
  </GlobalProvider>
);
