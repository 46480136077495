// Auth.jsx
import React from "react";
import ResetPasswordForm from "../components/LoginForm/ResetPasswordForm";

const ResetPassword = () => {
  return (
    <div className="w-full h-screen overflow-hidden  bg-white p-6">
      <ResetPasswordForm />
    </div>
  );
};

export default ResetPassword;
