// stylesSideTabData.js

export const getStylesSideTabData = (id) => {
  return [
    { title: "Colors", link: `/${id}/styles/colors` },
    { title: "Typography", link: `/${id}/styles/typography` },
    { title: "Spacing", link: `/${id}/styles/spacing` },
    // { title: "Grid", link: `/${id}/styles/grid` },
    { title: "Border Radius", link: `/${id}/styles/radius` },
    { title: "Elevation", link: `/${id}/styles/elevation` },
    { title: "Iconography", link: `/${id}/styles/iconography` },
    { title: "Accessibility", link: `/${id}/styles/accessibility` },
    { title: "Core tokens", link: `/${id}/styles/tokens` },
  ];
};

export const getComponentsSideData = (id) => {
  return [
    { title: "Button", link: `/${id}/components/button` },
    { title: "Alert", link: `/${id}/components/alert` },
    { title: "Inputs", link: `/${id}/components/inputs` },
    { title: "Textarea", link: `/${id}/components/textarea` },
    { title: "Avatar", link: `/${id}/components/avatar` },
    { title: "Avatar Group", link: `/${id}/components/avatarGroup` },
    { title: "Check box", link: `/${id}/components/checkbox` },
    { title: "Empty State", link: `/${id}/components/emptyState` },
    { title: "Modal", link: `/${id}/components/modal` },
    { title: "Pagination", link: `/${id}/components/pagination` },
    { title: "Radio button", link: `/${id}/components/radio` },
    { title: "Table", link: `/${id}/components/table` },
    { title: "Tabs", link: `/${id}/components/tabs` },
    { title: "Tag", link: `/${id}/components/tag` },
    { title: "Toaster", link: `/${id}/components/toaster` },
    { title: "Toggle", link: `/${id}/components/toggles` },
    { title: "Tooltip", link: `/${id}/components/tooltip` }
    // { title: "Menu", link: `/${id}/components/menu` },
  ];
};


export const getPatternsSideData = (id) => {
  return [
    { title: "Forms", link: `/${id}/patterns/forms` },
  ];
};

