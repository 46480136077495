import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import SideNavTabs from "components/DesignSystemPage/SideNavTabs";
import { getUser } from "hooks/storageUtils";
import { PrimaryLogo } from "components/Icons";
import UserLogo from "components/user/UserLogo";
import { useParams } from "react-router-dom";

const SettingsLayout = ({ children, sideTabs, title = "Profile settings" }) => {
  const { id } = useParams();
  const user = getUser();

  const defaultLogo = {
    component: <PrimaryLogo />,
    text: "Design",
  };

  const userLogo = {
    component: <UserLogo user={user} />,
    text: "Design",
  };

  const [companyLogo, setCompanyLogo] = useState(
    user?.company_logo ? userLogo : defaultLogo
  );

  useEffect(() => {
    setCompanyLogo(user?.company_logo ? userLogo : defaultLogo);
  }, [user.logo_url]);

  const defaultSideTabs = [
    { title: "My account", link: `/${id}/settings` },
    {
      title: "Oraganization settings",
      link: `/${id}/settings`,
      disabled: true,
    },
    { title: "General", link: `/${id}/general` },
    { title: "Subscription", link: `/${id}/subscriptions` },
    { title: "Team", link: `/${id}/teams` },
    // { title: "Email notifications", link: `/${id}/email-notifications` },
  ];
  // const profileSideTabs = [{ title: "Account", link: `/${id}/profile` }];

  // const effectiveSideTabs =
  //   location.pathname === `/${id}/profile` ? profileSideTabs : defaultSideTabs;

  // Clone each child and add the additional props
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, { setCompanyLogo: setCompanyLogo });
  });

  return (
    <div className='design-system-page d-flex h-full'>
      <ToastContainer />
      <SideNavTabs tabs={defaultSideTabs} logo={companyLogo} title={title} />
      <div className='tab-content-body w-100vw'>{childrenWithProps}</div>
    </div>
  );
};

export default SettingsLayout;
