function toPx(value) {
  // Use a regular expression to check px
  const match = value?.match(/^(-?\d+(\.\d+)?)px$/);

  if (match) {
    return value;
  } else {
    return `${value}px`;
  }
}

function removePx(value) {
  // Use a regular expression to check px
  const match = value?.match(/^(-?\d+(\.\d+)?)px$/);

  if (match) {
    return match[1];
  } else {
    return value;
  }
}

const getInitials = (name) => {
  if (!name) return;
  const parts = name.split(" ");
  const initials =
    parts.length > 1 ? parts[0][0] + parts[parts.length - 1][0] : parts[0][0];
  return initials.toUpperCase();
};

export { toPx, removePx, getInitials };
