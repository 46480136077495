import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { getStylesSideTabData } from "../../assets/data/sideTabData";
import PreviewRadius from "../../components/DesignSystemPage/Radius/PreviewRadius";
import RadiusUsage from "../../components/DesignSystemPage/Radius/RadiusUsage";
import { useGlobalContext } from "context/GlobalContext";

const DesignSystemRadius = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const bannerData = {
    heading: "Radius",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage"];
  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={tabs}
      sideTabs={getStylesSideTabData(id)}
      title='Foundation'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && <PreviewRadius />}
        {selectedTab === 1 && <RadiusUsage />}
        {/* {selectedTab === 2 && <div>content3</div>} */}
      </div>
    </DesignSystemLayout>
  );
};

export default DesignSystemRadius;
