import React, { useState, useEffect } from "react";
import "./AccessibilityStyles.scss";

import TabNavigation from "components/DesignSystemPage/TabNavigation/index";
import CommonModal from "components/Common/CommonModal";
import EditButton from "components/Buttons/EditButton";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import DraftEditor from "components/Common/DraftEditor/DraftEditor";
import PrimaryButton from "components/Common/PrimaryButton";
import { useGlobalContext } from "context/GlobalContext";
import { updatePage } from "api";
import Tooltip from "components/Tooltip";
import AuthAction from "components/auth/AuthAction";

const AccessibilityStyles = () => {
  const { designSystemMeta, setDesignSystemMeta } = useGlobalContext();
  const tabData = ["Principles", "Major styles"];
  const [page, setPage] = useState({});

  useEffect(() => {
    setPage(designSystemMeta?.pages?.find((page) => page.type === "ac"));
  }, []);

  useEffect(() => {
    const contentBlock = htmlToDraft(
      designSystemMeta?.pages?.find((page) => page.type === "ac")?.content ?? ""
    );
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const [isAddElevationModalOpen, setAddElevationModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const saveChanges = async () => {
    const contentHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const dataToSubmit = {
      id: page.id,
      content: contentHtml,
    };

    try {
      await updatePage(dataToSubmit, page?.id);
      setIsEditing(false);
      setPage({ ...page, content: contentHtml });
      updateState(contentHtml);
    } catch (error) {
      console.error("Failed to update page:", error.message);
    }
  };

  const updateState = (contentHtml) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    const newPages = newDesignSystemMeta.pages.map((p) => {
      if (p.id !== page.id) {
        return p;
      } else {
        return {
          ...p,
          content: contentHtml,
        };
      }
    });
    newDesignSystemMeta.pages = newPages;
    setDesignSystemMeta(newDesignSystemMeta);
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        <div className='plt-contents-container dis-p-0'>
          {isEditing ? (
            <div>
              <DraftEditor
                editorState={editorState}
                setEditorState={setEditorState}
              />
              <div className='dis-mt-20 dis-mb-40'>
                <PrimaryButton
                  onClick={() => setIsEditing(false)}
                  text='Cancel'
                  className='dss-btn dss-btn-secondary dss-btn-lg dis-mr-20'
                />
                <PrimaryButton
                  onClick={saveChanges}
                  text='Save'
                  className='dss-btn dss-btn-primary dss-btn-lg'
                />
              </div>
            </div>
          ) : (
            <div className='d-flex w-full justify-content-between'>
              <div
                className='content-display'
                dangerouslySetInnerHTML={{
                  __html: draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  ),
                }}
              />
              <AuthAction>
                <span className='dis-ml-24' onClick={() => setIsEditing(true)}>
                  <Tooltip position='top' arrow='btm-center' text='Edit content'>
                    <EditButton />
                  </Tooltip>
                </span>
              </AuthAction>
            </div>
          )}
        </div>
      </div>
      {/* <div className='plt-token-contents '>
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div> */}

      <CommonModal
        isOpen={isAddElevationModalOpen}
        onRequestClose={() => setAddElevationModalOpen(false)}
        title='Add icons'
        onApply={() => setAddElevationModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div className='plt-common-card-img-box m-auto'></div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default AccessibilityStyles;
