const formatDefaultTokens = (tokens, type) => {
  return tokens
    ? Object.entries(tokens).map(([key, value]) => ({
      key: `{${type}.${key}}`,
      value: value.value,
      varName: value.varName.replace("core-", ""),
      tokenName: value.tokenName.replace("dss-", "").split("-").join("."),
    }))
    : [];
};

/**
 *
 * @param {object} tokens
 * @returns {convertedTokens}
 */
const convertDefaultTokens = (tokens) => {
  const types = [
    "primary",
    "secondary",
    "supportingColor1",
    "supportingColor2",
    "supportingColor3",
    "neutrals",
  ];
  const semanticType = ["negative", "caution", "positive", "informative"];

  // Normal types
  types.map((type) => {
    tokens["converted" + type + "Tokens"] = formatDefaultTokens(
      tokens.core[type],
      type
    );
  });

  // Semantic types
  semanticType.map((semantic) => {
    tokens["converted" + semantic + "Tokens"] = formatDefaultTokens(
      tokens.core?.semantics[semantic],
      `semantics.${semantic}`
    );
  });

  return tokens;
};

/**
 *
 * @param {tokens} data
 * @param {Enum('primary','secondary','supporting-color-1')} type
 * @returns {String} color
 */
const getBaseColor = (data, type) => {
  return data["converted" + type + "Tokens"]?.find(
    (p) => p.key == `{${type}.600}`
  )?.value;
};

//Update DOM style element
const updateCSSVariables = (varName, value) => {
  document.documentElement.style.setProperty(varName, value);
};

//Update DOM style element
const updateCSSVariablesAll = (obj) => {
  Object.entries(obj).map(([varName, value]) => {
    document.documentElement.style.setProperty(varName, value);
  });
};

//Update DOM style element
const updateCSSVariablesAllObj = (obj) => {
  Object.entries(obj).map(([varName, value]) => {
    const cssVarName = `${varName}`;
    const cssVarValue = getComputedStyle(document.documentElement).getPropertyValue(cssVarName).trim();
    document.documentElement.style.setProperty(varName, cssVarValue);
  });
};

const getValueByPath = (obj, path) => {
  return path?.split(".").reduce((acc, part) => acc && acc[part], obj);
};

const filterTypeFromTokens = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return d.key.includes(filter);
  });

const removeTypeFromTokens = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return !d.tokenName.includes(filter);
  });
//filter
const filterTokenByType = (data, filter) =>
  data &&
  Object.values(data)?.filter((d) => {
    return d.type == filter;
  });

const destructureTokens = (data) => {
  return Object.entries(data).map(
    ([key, { value, type, varName, tokenName, className }]) => {
      let destructuredColors = {};
      if (type === "color") {
        return (destructuredColors = {
          ...destructuredColors,
          ...{ value, type, varName, tokenName, className },
        });
      }
    }
  );
};

const replaceCharfromKey = (variable) => {
  return variable?.replace(/[{}]/g, "");
};

const updateValueByPath = (obj, path, value) => {
  const keys = path.split(".");
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    current = current[keys[i]] = current[keys[i]] || {};
  }

  if (current[keys[keys.length - 1]]) {
    current[keys[keys.length - 1]].value = value;
  } else {
    current[keys[keys.length - 1]] = { value: value };
  }
};

const constructBoxShadow = (shadow) => {
  if (!shadow) return '';

  const { x, y, blur, spread, color } = shadow;
  return `${x} ${y} ${blur} ${spread} ${color}`;
};


export {
  convertDefaultTokens,
  getBaseColor,
  updateCSSVariables,
  getValueByPath,
  updateCSSVariablesAll,
  updateCSSVariablesAllObj,
  filterTypeFromTokens,
  removeTypeFromTokens,
  filterTokenByType,
  destructureTokens,
  replaceCharfromKey,
  updateValueByPath, constructBoxShadow
};
