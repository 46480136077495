import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { getStylesSideTabData } from "../../assets/data/sideTabData";
import ScaleTokenSpacing from "../../components/DesignSystemPage/Spacing/ScaleTokenSpacing";
import TypoUsage from "../../components/DesignSystemPage/Typography/Usage";
import { useGlobalContext } from "context/GlobalContext";

const Spacing = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };
  const bannerData = {
    heading: "Spacing",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Scales and tokens", "Usage"];
  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={tabs}
      sideTabs={getStylesSideTabData(id)}
      title='Foundation'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && <ScaleTokenSpacing />}
        {selectedTab === 1 && <TypoUsage />}
        {/* {selectedTab === 2 && <ScaleTokenSpacing/>} */}
      </div>
    </DesignSystemLayout>
  );
};

export default Spacing;
