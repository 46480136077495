import React, { useState, useEffect, useCallback } from "react";
import CommonModal from "components/Common/CommonModal";
import Textarea from "components/Textarea";

const EditDescriptionModal = ({
  isOpen,
  onRequestClose,
  onApply,
  title,
  data,
}) => {
  const [description, setDescription] = useState(data.description ?? "");
  const [charCount, setCharCount] = useState(0);
  const maxChar = 100;
  const handleApply = () => {
    onApply(data, description);
    onRequestClose();
  };

  const handleInputChange = (value) => {
    if (value.length > maxChar) {
      return;
    }
    setDescription(value);
    setCharCount(value.length);
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Save'
      bodyClassName='overflow-unset'
    >
      <div className='plt-modal-body-content'>
        <div className='  dis-mb-32'>
          <div className='modal-textarea-wrapper dis-mt-32 dis-mb-24 w-full'>
            <h6 className='modal-textarea-title plt-ui-text-sm-regular plt-font-color-primary'>
              Add description
            </h6>
            <textarea
              className='modal-textarea w-100'
              placeholder='Text'
              value={description}
              onChange={(e) => handleInputChange(e.target.value)}
            ></textarea>
            <div className='modal-textarea-info d-flex align-items-center justify-content-between dis-mt-4'>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                Helper text
              </span>
              <span className='modal-textarea-desc plt-ui-text-sm-regular'>
                {charCount}/{maxChar}
              </span>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default EditDescriptionModal;
