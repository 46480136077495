import React, { useEffect, useState } from "react";
import CommonModal from "components/Common/CommonModal";
import FormValidationError from "components/FormValidationError";
import { isPasswordStrong } from "helpers/auth";
import { isPasswordConfirmed } from "helpers/auth";
import { EyeButton, EyeButtonOpen } from "components/Icons";
import "./ChangePasswordModal.scss";
import ForgotPasswordModal from "./ForgotPasswordModal";
import VerificationModal from "./VerificationModal";

const ChangePasswordModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] =
    useState(false);

  const openForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true);
    onRequestClose();
  };
  const closeForgotPasswordModal = () => {
    setForgotPasswordModalOpen(false);
  };
  const [isVerificationModalOpen, setVerificationModalOpen] = useState(false);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    setErrors({});
    return () => {
      setErrors({});
    };
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowPassword2(!showPassword2);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowPassword3(!showPassword3);
  };

  const handleApply = () => {
    if (!oldPassword)
      setErrors((errors) => ({
        ...errors,
        oldPassword: "Please enter old password",
      }));
    if (!newPassword)
      setErrors((errors) => ({
        ...errors,
        newPassword: "Please enter new password",
      }));
    if (!confirmPassword)
      setErrors((errors) => ({
        ...errors,
        confirmPassword: "Please enter confirm password",
      }));
    if (newPassword && !isPasswordStrong(newPassword))
      setErrors((errors) => ({
        ...errors,
        confirmPassword: "Please enter strong password",
      }));
    if (confirmPassword && !isPasswordConfirmed(newPassword, confirmPassword))
      setErrors((errors) => ({
        ...errors,
        confirmPassword: "Please doesnot match",
      }));

    let request = {
      oldPassword,
      newPassword,
      confirmPassword,
    };

    onApply(request);
    onRequestClose();
  };

  return (
    <div>
      <CommonModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        title={title}
        onApply={handleApply}
        footerClassName='plt'
        onCancelText='Cancel'
        onApplyText='Change'
        bodyClassName='change-pswd-modal'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-select-dropdown-wrapper dis-ml-24 dis-mr-24 dis-pt-12 dis-pb-12'>
            {/* Old password field */}
            <div className='upload-font-content d-flex align-items-center justify-content-between dis-mb-12 w-100'>
              <div className='plt-select-dropdown-wrapper w-100'>
                <h6 className='plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4'>
                  Current password
                </h6>
                <div className='modal-value-block w-100'>
                  <div className='relative'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      // type='password'
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      className='absolute eye-button'
                    >
                      {showPassword ? <EyeButton /> : <EyeButtonOpen />}
                    </span>
                  </div>
                </div>
                {errors?.oldPassword && (
                  <FormValidationError error={errors?.oldPassword} />
                )}
              </div>
            </div>
            <span
              className='plt-modal-link plt-ui-text-sm-medium cursor-pointer d-block w-fit dis-mb-12'
              onClick={openForgotPasswordModal}
            >
              Forgot password?
            </span>
            {/* New password field*/}
            <div className='upload-font-content d-flex align-items-center justify-content-between dis-mb-12 w-100'>
              <div className='plt-select-dropdown-wrapper w-100'>
                <h6 className='plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4'>
                  New password
                </h6>
                <div className='modal-value-block w-100'>
                  <div className='relative'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type={showPassword2 ? "text" : "password"}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <span
                      onClick={toggleNewPasswordVisibility}
                      className='absolute eye-button'
                    >
                      {showPassword2 ? <EyeButton /> : <EyeButtonOpen />}
                    </span>
                  </div>
                </div>
                {errors?.newPassword && (
                  <FormValidationError error={errors?.newPassword} />
                )}
              </div>
            </div>
            {/* Confirm password field*/}
            <div className='upload-font-content d-flex align-items-center justify-content-between dis-mb-12 w-100'>
              <div className='plt-select-dropdown-wrapper w-100'>
                <h6 className='plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4'>
                  Confirm password
                </h6>
                <div className='modal-value-block w-100'>
                  <div className='relative'>
                    <input
                      className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                      type={showPassword3 ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      className='absolute eye-button'
                    >
                      {showPassword3 ? <EyeButton /> : <EyeButtonOpen />}
                    </span>
                  </div>
                </div>
                {errors?.confirmPassword && (
                  <FormValidationError error={errors?.confirmPassword} />
                )}
              </div>
            </div>
          </div>
        </div>
      </CommonModal>

      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onRequestClose={closeForgotPasswordModal}
        // onApply={handleChangePasswordApply}
        title='Verification link'
        onApply={() => {
          closeForgotPasswordModal();
          setVerificationModalOpen(true);
        }}
      />

      <VerificationModal
        isOpen={isVerificationModalOpen}
        onRequestClose={() => setVerificationModalOpen(false)}
        // onApply={handleChangePasswordApply}
        title='Verification'
      />
    </div>
  );
};

export default ChangePasswordModal;
