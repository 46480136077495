// Auth.jsx
import React from "react";
import SignUpForm from "../components/LoginForm/SignUpForm";

const SignIn = () => {
  return (
    <div className="w-full h-screen overflow-hidden  bg-white p-6">
      <SignUpForm />
    </div>
  );
};

export default SignIn;
