import React from "react";
import radioguidelines1 from "../../../assets/images/png/radioguidelines1.png";
import radioguidelines2 from "../../../assets/images/png/radioguidelines2.png";


const ComponentPrimaryDatas = [
  {
    id: 1,
    title: "Do",
    titleClassname: "active",
    image: radioguidelines1,
    description:
      "Do let text wrap beneath the radio button so the control and label are top aligned.",
  },
  {
    id: 2,
    title: "Don’t",
    titleClassname: "inactive",
    image: radioguidelines2,
    description:
      "Do not vertically center wrapped text with the radio button. ",
  },

];

const GuidelineCardRadio = () => {
  return (
    <div className='guideline-card d-flex justify-content-between'>
      {ComponentPrimaryDatas.map((data) => (
        <div key={data.id} className='guideline-wrapper'>
          <h6
            className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}
          >
            {data.title}
          </h6>
          <div className='guideline-detail-wrapper d-flex align-items-center justify-content-center'>
            <div className='guideline-img'>
              <img src={data.image} alt='userimage' />
            </div>
          </div>
          <p className='guideline-desc plt-body-md dis-mt-12'>
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GuidelineCardRadio;
