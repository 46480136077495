import React from "react";
import "./UsageCard.scss";
// import EditButton from "../../Buttons/EditButton";

const UsageCard = ({ UsageBoxData }) => {
  return (
    <div className="plt-usage-card-item">
    <span className={`d-block plt-badge plt-badge-status plt-body-compact-md font-weight-500 dis-mb-8 ${UsageBoxData.statusClass}`}>{UsageBoxData.status}</span>
       <div  className="plt-common-card dis-p-24 dis-pb-0">
            <div className="plt-common-card-content d-flex h-full">
              <p className="plt-common-card-info plt-body-md font-weight-400">
                {UsageBoxData.content1}
              </p>
              <p className="plt-common-card-info plt-body-md font-weight-400">
                {UsageBoxData.content2}
              </p>
          
            </div>
      </div>
    </div>
  );
};

export default UsageCard;
