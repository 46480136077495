import React from "react";
import { getUser } from "hooks/storageUtils";
import { ROLES } from "constants";

const DomainTable = () => {
  const user = getUser();
  return (
    <div className='plt-content-block dis-mb-0'>
      <div className='plt-content-wrapper dis-mb-40'>
        <div className='plt-content-block-top d-flex justify-content-between align-items-center'>
          <h6 className='plt-contents-head plt-heading-xl plt-font-color-primary'>
            Domains
          </h6>
        </div>

        <div className='plt-table-wrapper'>
          <div className='plt-table-container'>
            <table className='plt-table'>
              <thead>
                <tr>
                  <th className='plt-table-head plt-font-color-secondary font-weight-600'>
                    Domain
                  </th>
                  <th className='plt-table-head plt-font-color-secondary font-weight-600'>
                    Role
                  </th>
                </tr>
              </thead>
              <tbody>
                {user.domains.map((domain) => {
                  return (
                    <tr key={domain.id}>
                      <td>
                        <div className='plt-color-token-name d-flex align-items-center'>
                          <p className='plt-color-description plt-body-md plt-font-color-secondary'>
                            {domain.name}
                          </p>
                          <div className='plt-table-hover-icon dis-ml-8'></div>
                        </div>
                      </td>
                      <td>
                        <p className='plt-color-description plt-body-md plt-font-color-secondary'>
                          {
                            ROLES.find(
                              (role) => role.value === domain?.pivot?.role_id
                            )?.label
                          }
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainTable;
