import React from "react";
import "./EditButton.scss";
import { EditButtonicon } from "../../Icons";

const EditButton = () => {
  return (
    <button className="plt-iconbtn edit-btn">
      <span className="plt-btn-icon">
        <EditButtonicon />
      </span>
    </button>
  );
};

export default EditButton;
