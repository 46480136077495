const parseCssVariables = (cssText) => {
  const regex = /--([^:\s]+):\s*([^;]+)/g;
  let match;
  const variables = {};
  while ((match = regex.exec(cssText)) !== null) {
    const [, name, value] = match;
    variables[name.trim()] = value.trim();
  }
  return variables;
};

export { parseCssVariables };
