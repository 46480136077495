import React from "react";
import "./GuidelineCard.scss";
import guidelines1 from "../../../assets/images/png/guidelines1.png";
import guidelines2 from "../../../assets/images/png/guidelines2.png";
import guidelines3 from "../../../assets/images/png/guidelines3.png";
import guidelines4 from "../../../assets/images/png/guidelines4.png";

const ComponentPrimaryDatas = [
  {
    id: 1,
    title: "Do",
    titleClassname: "active",
    image: guidelines1,
    description:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement. ",
  },
  {
    id: 2,
    title: "Don’t",
    titleClassname: "inactive",
    image: guidelines2,
    description:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement. ",
  },
  {
    id: 3,
    title: "Do",
    titleClassname: "active",
    image: guidelines3,
    description:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement. ",
  },
  {
    id: 4,
    title: "Don’t",
    titleClassname: "inactive",
    image: guidelines4,
    description:
      "Buttons are fundamental elements in user interfaces, serving as interactive components that facilitate user actions and engagement. ",
  },
];

const GuidelineCard = () => {
  return (
    <div className='guideline-card d-flex justify-content-between'>
      {ComponentPrimaryDatas.map((data) => (
        <div key={data.id} className='guideline-wrapper'>
          <h6
            className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}
          >
            {data.title}
          </h6>
          <div className='guideline-detail-wrapper d-flex align-items-center justify-content-center'>
            <div className='guideline-img'>
              <img src={data.image} alt='userimage' />
            </div>
          </div>
          <p className='guideline-desc plt-body-md dis-mt-12'>
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GuidelineCard;
