import React, { useState, useRef } from "react";
import "assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "assets/data/sideTabData";
import PreviewTabsComponent from "components/DesignSystemPage/TabsComponent/PreviewTabsComponent";
import { useGlobalContext } from "context/GlobalContext";
import UsageEditor from "components/DesignSystemPage/UsageEditor";


const TabsComponents = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const bannerData = {
    heading: "Tab",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewTabsComponent setSelectedTab={setSelectedTab} />
        );
      case 1:
        return (
          <div>
            <UsageEditor pageType={"tab_usage"} />
          </div>
        );
      case 2:
        return <div>content3</div>;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default TabsComponents;
