import React, { useRef, useState } from "react";Tag
import "./Tag.scss";
import {  ButtonIconSample, CloseIcon, StatusIcon } from "../Icons";

function Tag({size,typo,label="Basic tag",disable,prefixIcon,statusIcon,prefixClass,mode,closeIconClass,counter=0}) {
  return (
    <div className={`dss-tag dss-tag-${size} ${mode == "closable" && 'dss-tag-closable'} ${disable && 'disabled'}`}>
    {
prefixIcon == true || false ? <span className={`dss-prefix-icon ${prefixClass}`}>
        <ButtonIconSample color={disable ? '#17171740' : 'black'}/>
      </span> : <span className={` ${prefixClass}`}>
        {prefixIcon}
      </span> 
    }
     {
statusIcon == true || false ? <span className={`dss-prefix-icon ${prefixClass}`}>
        <StatusIcon color={disable ? '#17171740' : 'black'}/>
      </span> : <span className={` ${prefixClass}`}>
        {statusIcon}
      </span> 
    }
      <span className={`dss-tag-label dss-${typo}`}>{label}</span>
      {
        mode == "counter" ? <span className={`dss-tag-counter dss-${typo}`}>| {counter}</span> : mode == "closable" ? <span className={`dss-close-icon ${closeIconClass}`}>
          <CloseIcon />
        </span> : null
      }
    </div>
  )
}

export default Tag;
