import React, { useState, useEffect } from "react";
import { filterTokenByType } from "helpers/token";
import { useParams } from "react-router-dom";
import { fetchTokens } from "api";
import { getUser } from "hooks/storageUtils";

const AllTokenData = () => {
  const { id } = useParams();
  const [tokens, setTokens] = useState([]);
  const [lightTokens, setLightTokens] = useState([]);
  useEffect(() => {
    const fetchDesignSystem = async () => {
      try {
        const user = getUser();
        const { core, light } = await fetchTokens(user.id, id, "");
        setTokens(core);
        setLightTokens(light);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    if (id) {
      fetchDesignSystem();
    }
  }, [id]);

  const colorData = filterTokenByType(tokens, "color");
  const typographyData = filterTokenByType(tokens, "typography");
  const spacingData = filterTokenByType(tokens, "spacing");
  const elevationData = filterTokenByType(tokens, "boxShadow");
  const borderRadiusData = filterTokenByType(tokens, "borderRadius");
  const sizingData = filterTokenByType(tokens, "sizing");
  const fontData = [
    ...filterTokenByType(tokens, "fontFamilies"),
    ...filterTokenByType(tokens, "fontSizes"),
    ...filterTokenByType(tokens, "fontWeights"),
    ...filterTokenByType(tokens, "lineHeights"),
    ...filterTokenByType(tokens, "letterSpacing"),
    ...filterTokenByType(tokens, "paragraphSpacing"),
    ...filterTokenByType(tokens, "textDecoration"),
    ...filterTokenByType(tokens, "textCase"),
  ];
  const borderWidthData = filterTokenByType(tokens, "borderWidth");
  const lightTokenData = filterTokenByType(lightTokens, "color");

  return [
    {
      id: 1,
      title: "Tokens",
      sub_heading: "Color",
      type: "color",
      colorData: colorData,
    },
    {
      id: 2,
      title: "Tokens",
      sub_heading: "Typography",
      type: "typography",
      colorData: typographyData,
    },
    {
      id: 3,
      title: "Tokens",
      sub_heading: "Spacing",
      type: "spacing",
      colorData: spacingData,
    },
    {
      id: 4,
      title: "Tokens",
      sub_heading: "Border Radius",
      type: "borderRadius",
      colorData: borderRadiusData,
    },
    {
      id: 5,
      title: "Tokens",
      sub_heading: "Elevation",
      type: "boxShadow",
      colorData: elevationData,
    },
    {
      id: 6,
      title: "Tokens",
      sub_heading: "Sizing",
      type: "sizing",
      colorData: sizingData,
    },
    {
      id: 7,
      title: "Tokens",
      sub_heading: "Font",
      type: "font",
      colorData: fontData,
    },
    {
      id: 8,
      title: "Tokens",
      sub_heading: "Border Width",
      type: "borderWidth",
      colorData: borderWidthData,
    },
    {
      id: 9,
      title: "Tokens",
      sub_heading: "Color light",
      type: "color",
      colorData: lightTokenData,
    },
  ];
};

export default AllTokenData;
