import React, { useState, useEffect } from "react";
import "./IconographyStyles.scss";
import AddButton from "components/Buttons/AddButton";
import CommonModal from "components/Common/CommonModal";
import EditButton from "components/Buttons/EditButton";
import IconographyBox from "./IconographyBox";
import placeholder from "components/../assets/images/svg/iconography/placeholder.svg";
import error from "components/../assets/images/svg/iconography/error.svg";
import warning from "components/../assets/images/svg/iconography/warning.svg";
import success from "components/../assets/images/svg/iconography/success.svg";
import info from "components/../assets/images/svg/iconography/info.svg";
import CustomSelect from "components/Common/CustomSelect";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useGlobalContext } from "context/GlobalContext";
import { updatePage } from "api";
import Tooltip from "components/Tooltip";
import AuthAction from "components/auth/AuthAction";
import { SearchIcon } from "components/Icons";


const IconographyStyles = () => {
  const { designSystemMeta, setDesignSystemMeta } = useGlobalContext();
  const tabData = ["Icon library"];
  const [page, setPage] = useState({});

  useEffect(() => {
    setPage(designSystemMeta?.pages?.find((page) => page.type === "ic"));
  }, []);

  useEffect(() => {
    const contentBlock = htmlToDraft(
      designSystemMeta?.pages?.find((page) => page.type === "ic")?.content ?? ""
    );
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, []);

  const IconLibraryDatas = [
    {
      id: 1,
      img: placeholder,
      content: "icon-placeholder",
    },
    {
      id: 2,
      img: error,
      content: "icon-cancel",
    },
    {
      id: 3,
      img: warning,
      content: "icon-caution",
    },
    {
      id: 4,
      img: success,
      content: "icon-correct",
    },
    {
      id: 5,
      img: info,
      content: "icon-Information",
    },
  ];
  const fonts = ["General", "General2", "General3"];
  const datas = ["All icons", "App catalogue", "Data", "Research", "Travel"];
  const [selecteStyles, setSelectedStyles] = useState("General");
  const [selecteDatas, setSelectedDatas] = useState("All icons");
  const [isAddElevationModalOpen, setAddElevationModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleStylesChange = (value) => setSelectedStyles(value);

  const handleDatasChange = (value) => setSelectedDatas(value);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const saveChanges = async () => {
    const contentHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const dataToSubmit = {
      id: page.id,
      content: contentHtml,
    };

    try {
      await updatePage(dataToSubmit, page?.id);
      setIsEditing(false);
      setPage({ ...page, content: contentHtml });
      updateState(contentHtml);
    } catch (error) {
      console.error("Failed to update page:", error.message);
    }
  };

  const updateState = (contentHtml) => {
    const newDesignSystemMeta = { ...designSystemMeta };
    const newPages = newDesignSystemMeta.pages.map((p) => {
      if (p.id !== page.id) {
        return p;
      } else {
        return {
          ...p,
          content: contentHtml,
        };
      }
    });
    newDesignSystemMeta.pages = newPages;
    setDesignSystemMeta(newDesignSystemMeta);
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        <div className="plt-dropdown-container d-flex dis-mb-32">
            <div className='plt-select-dropdown-wrapper dis-mr-16'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Selected style set:
              </h6>
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-style'>
                <CustomSelect
                  options={fonts}
                  selectedOption={selecteStyles}
                  onSelect={handleStylesChange}
                />
              </div>
            </div>
            <div className="plt-input-search">
              <div className="plt-input-wrapper plt-input">
                <div className="plt-input-container">
                <span className="plt-input-icon">
                  <SearchIcon/>
                </span>
                  <input
                    type='text'
                    placeholder="search"
                    onChange={(e) => setName(e.target.value)}
                    className='plt-input-field'
                  />
                </div>
              </div>
            </div>
            <div className='plt-select-dropdown-wrapper d-flex align-items-center ml-auto'>
              <div className='plt-dropdown-wrapper plt-dropdown-wrapper-icons'>
                <CustomSelect
                  options={datas}
                  selectedOption={selecteDatas}
                  onSelect={handleDatasChange}
                />
              </div>
            </div>          
        </div>

        <div className='plt-content-wrapper dis-mb-24'>
            <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
            <h6 className="plt-contents-head plt-heading-md plt-font-color-primary">Icon library</h6>
              <div className='plt-icon-wrapper d-flex justify-content-between align-items-center'>
                <span
                  onClick={() => setAddElevationModalOpen(true)}
                  className='plt-function-icon'
                >
                  <AddButton />
                </span>
                <span
                  onClick={() => setModalOpen(true)}
                  className='plt-function-icon'
                >
                  <EditButton />
                </span>
              </div>
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              It is important to maintain the same style of radius for the same
              types components. Inconsistency will create a messy UI.
            </p>
        </div>

        <div className='plt-iconography-card-wrapper d-flex'>
          {IconLibraryDatas.map((data) => (
            <div key={data.id} className='plt-icon-card'>
              <IconographyBox IconographyBoxData={data} />
            </div>
          ))}
        </div>
      
      </div> 

      <CommonModal
        isOpen={isAddElevationModalOpen}
        onRequestClose={() => setAddElevationModalOpen(false)}
        title='Add icons'
        onApply={() => setAddElevationModalOpen(false)}
        onCancelText='Cancel'
        onApplyText='Apply'
      >
        <div className='plt-modal-body-content'>
          <div className='plt-preview plt-heading-xl dis-mb-32'>
            <div className='plt-common-card-img-box m-auto'></div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default IconographyStyles;
