const commonDesc =
  "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page.";

const inputData = [
  {
    title: "Normal",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: false,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: false,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal With Tailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: false,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal With Leading And Tailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: false,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal Prefix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: true,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal Prefix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: true,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal Prefix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: true,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal Prefix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: false,
    tag: false,
  },
  {
    title: "Normal Suffix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: false,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Suffix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: false,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Suffix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: false,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Suffix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: false,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Prefix And Suffix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: true,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Prefix And Suffix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: true,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Prefix And Suffix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Prefix And Suffix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: false,
  },
  {
    title: "Normal Prefix And Suffix With Leading And Trailing Icon and Tags",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
  },
  {
    title: "Disabled",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
    disabled: true,
  },
  {
    title: "Readonly",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
    readOnlyState: true,
  },
];

const previewInputData = [
  {
    title: "Normal",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: false,
    suffix: false,
  },
];


const inputDataDense = [
  {
    title: "Dense - Normal",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: false,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: false,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal With Tailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: false,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal With Leading And Tailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: false,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: true,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: true,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: true,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: false,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Suffix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: false,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Suffix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: false,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Suffix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: false,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Suffix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: false,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix And Suffix",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: false,
    prefix: true,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix And Suffix With Leading Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: false,
    prefix: true,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix And Suffix With Trailing Icon",
    desc: commonDesc,
    leadingIcon: false,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix And Suffix With Leading And Trailing Icon",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: false,
    className: 'dss-input-sm',
  },
  {
    title: "Dense - Normal Prefix And Suffix With Leading And Trailing Icon and Tags",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
    className: 'dss-input-sm',
  },
  {
    title: "Disabled",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
    disabled: true,
  },
  {
    title: "Readonly",
    desc: commonDesc,
    leadingIcon: true,
    tailingIcon: true,
    prefix: true,
    suffix: true,
    tag: true,
    readOnlyState: true,
  },
];

export default { previewInputData, inputData, inputDataDense };
