import React, { useState, useEffect } from "react";
import HomeHeader from "../components/Home/HomeHeader/HomeHeader";
import HomeMain from "../components/Home/HomeMain/HomeMain";
import { fetchDesignSystem } from "../api";
import { useLocation } from "react-router-dom";
import { UserIcon } from "./../components/Icons/index";
import { useGlobalContext } from "context/GlobalContext";

const Home = () => {
  const location = useLocation();
  const { designSystems, setDesignSystems, currentDomain } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(true);
  const userIdState = location?.state?.userId;
  const userIdStateToken = location?.state?.token;

  const user = JSON.parse(localStorage.getItem("user"));
  const userToken = localStorage.getItem("token"); // Do not parse, as it's likely a string

  useEffect(() => {
    const fetchDesignSystems = async () => {
      try {
        const userId = userIdState || user?.id;
        const userTokenFinal = userIdStateToken || userToken;

        if (userId && userTokenFinal) {
          const data = await fetchDesignSystem(userId, userTokenFinal);
          setDesignSystems(data);
        } else {
          console.error("User ID or token not found");
        }
      } catch (error) {
        console.error("Failed to fetch design systems", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDesignSystems();
  }, [userIdState, userIdStateToken, user?.id, userToken, currentDomain]);

  return (
    <div>
      <HomeHeader />
      <HomeMain
        designSystems={designSystems}
        setDesignSystems={setDesignSystems}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Home;
