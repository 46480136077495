import React, { useState } from "react";
import { EyeButton, EyeButtonOpen, ErrorIcon } from "../Icons/index";
import { useNavigate, useLocation } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetPassword } from "../../api";
import "./SignUpForm.scss";
import { SyncLoader } from "react-spinners";

const ResetPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  const validatePassword = (password) => {
    if (
      password.length < 6 ||
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/\d/.test(password) ||
      !/[!@#$%^&*]/.test(password)
    ) {
      return "Minimum of 6 characters, with upper and lowercase letters, a number, and a symbol.";
    }
    return "";
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const error = validatePassword(newPassword);
    setPasswordError(error);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmedPassword = e.target.value;
    setConfirmedPassword(newConfirmedPassword);
    if (newConfirmedPassword !== password) {
      setConfirmPasswordError("Password doesn't match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (confirmedPassword !== password) {
      setConfirmPasswordError("Password doesn't match.");
      return;
    }

    setIsLoading(true);

    const request = {
      email: email,
      password: password,
      password_confirmation: confirmedPassword,
      token: token,
    };

    try {
      const response = await resetPassword(request);
      toast.success("Password reset successful");
      navigate("/login");
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message || "An unexpected error occurred";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <AuthLayout>
      <div className="register-container flex flex-column w-full h-full">
        <ToastContainer />
        <div className="w-full">
          <h4 className="plt-heading-lg plt-font-color-primary dis-px-auto">
            Set new password
          </h4>
          <p className="info-msg plt-body-md plt-font-color-secondary dis-mt-12">
            Enter your details below and change your password
          </p>
          <form className="flex flex-col dis-mt-40" onSubmit={handleSubmit}>
            <div className="label-input-wrapper">
              <label
                htmlFor="password"
                className="input-label plt-ui-text-md-medium plt-font-color-primary"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  tabIndex={5}
                  autoFocus={true}
                  value={password}
                  onChange={handlePasswordChange}
                  className={`input-field-custom relative w-full ${passwordError ? "border-red-500" : ""}`}
                />
                <span onClick={togglePasswordVisibility} className="absolute eye-button">
                  {showPassword ? <EyeButton /> : <EyeButtonOpen />}
                </span>
              </div>
              <p className={`${passwordError ? "error-msg" : "text-[#BCBCBC]"} plt-body-xs d-flex`}>
                {passwordError && (
                  <span className="plt-error-icon dis-mr-4">
                    <ErrorIcon />
                  </span>
                )}
                <span className={`${passwordError ? "error-msg" : "text-[#BCBCBC]"} error-msg-change plt-body-xs dis-mt-0`}>
                  {passwordError || "Minimum of 6 characters, with upper and lowercase and a number and symbol"}
                </span>
              </p>
            </div>
            <div className="label-input-wrapper dis-mt-24">
              <label
                htmlFor="confirmPassword"
                className="input-label plt-body-md plt-font-color-primary"
              >
                Confirm password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="Confirm Password"
                tabIndex={6}
                value={confirmedPassword}
                onChange={handleConfirmPasswordChange}
                className={`input-field-custom w-full ${confirmPasswordError ? "border-red-500" : ""}`}
              />
              <p className={`${confirmPasswordError ? "error-msg" : "text-red-[#BCBCBC]"} plt-body-xs d-flex`}>
                {confirmPasswordError && (
                  <>
                    <span className="plt-error-icon dis-mr-4">
                      <ErrorIcon />
                    </span>
                    <span className={`${confirmPasswordError ? "error-msg" : "text-[#BCBCBC]"} error-msg-change plt-body-xs dis-mt-0`}>
                      {confirmPasswordError || "Passwords must match."}
                    </span>
                  </>
                )}
              </p>
            </div>
            <button
              type="submit"
              tabIndex={6}
              className="primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32"
              disabled={isLoading}
            >
              {isLoading ? (
                <SyncLoader color="#fff" loading={true} width="100%" height="100%" />
              ) : (
                "Reset password"
              )}
            </button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
