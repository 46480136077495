import { useCallback, useEffect } from "react";

/**
 * A custom hook that combines scrolling to sections and syncing tab states based on scroll position.
 *
 * @param {Array} sectionRefs - An array of React refs pointing to scrollable sections.
 * @param {number} activeTab - Currently active tab index.
 * @param {Function} setActiveTab - State setter function to update the active tab.
 * @param {string} [containerClass="tab-content-body"] - Class name of the scrollable container.
 * @param {number} [offset=0] - Optional offset to account for fixed headers or other elements.
 */
const useScrollManagement = (
  sectionRefs,
  activeTab,
  setActiveTab,
  containerClass = "tab-content-body",
  offset = 0
) => {
  const scrollToSection = useCallback(
    (index) => {
      const tabContentContainers = document.getElementsByClassName(containerClass);
      if (
        tabContentContainers.length > 0 &&
        sectionRefs[index] &&
        sectionRefs[index].current
      ) {
        const tabContentContainer = tabContentContainers[0];
        const sectionTop =
          sectionRefs[index].current.offsetTop -
          tabContentContainer.offsetTop -
          offset;
        tabContentContainer.scrollTop = sectionTop - 40;
      }
    },
    [containerClass, sectionRefs, offset]
  );

  const handleScroll = useCallback(() => {
    const tabContentContainers = document.getElementsByClassName(containerClass);
    if (tabContentContainers.length > 0) {
      const tabContentContainer = tabContentContainers[0];
      const scrollOffset =
        tabContentContainer.scrollTop +
        tabContentContainer.getBoundingClientRect().top;
      const currentPosition = scrollOffset + window.innerHeight / 2;

      let currentSectionIndex = sectionRefs.findIndex((ref) => {
        if (ref.current) {
          const start = ref.current.offsetTop + tabContentContainer.offsetTop;
          const end = start + ref.current.offsetHeight;
          return currentPosition >= start && currentPosition < end;
        }
        return false;
      });

      // Handle case when two elements are in view and at the bottom of the container
      if (currentSectionIndex === -1 && tabContentContainer.scrollHeight - tabContentContainer.scrollTop <= tabContentContainer.clientHeight + 1) {
        // Check which section is closer to the bottom
        const lastSectionIndex = sectionRefs.length - 1;
        const secondLastSectionIndex = sectionRefs.length - 2;
        const lastSectionRef = sectionRefs[lastSectionIndex];
        const secondLastSectionRef = sectionRefs[secondLastSectionIndex];

        if (lastSectionRef.current && secondLastSectionRef.current) {
          const lastSectionBottom = lastSectionRef.current.offsetTop + lastSectionRef.current.offsetHeight;
          const containerBottom = tabContentContainer.scrollTop + tabContentContainer.clientHeight;

          if (containerBottom >= lastSectionBottom) {
            currentSectionIndex = lastSectionIndex;
          } else {
            currentSectionIndex = secondLastSectionIndex;
          }
        }
      }

      if (currentSectionIndex !== -1 && currentSectionIndex !== activeTab) {
        setActiveTab(currentSectionIndex);
      }
    }
  }, [activeTab, sectionRefs, containerClass, setActiveTab]);

  useEffect(() => {
    const tabContentContainers = document.getElementsByClassName(containerClass);
    if (tabContentContainers.length > 0) {
      tabContentContainers[0]?.addEventListener("scroll", handleScroll);
      return () => {
        tabContentContainers[0]?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll, containerClass]);

  return scrollToSection;
};

export default useScrollManagement;
