import React from "react";
import "./DetailItem.scss";


const DetailItem = ({ number, title, description }) => (
  <li className="plt-btn-plt-detail-list d-flex">
    <span className="plt-heading-xs plt-font-color-primary">{number}.</span>
    <p className="plt-body-md"><span className="plt-heading-xs plt-font-color-primary dis-mr-8">{title}:</span>{description}</p>
  </li>
);


export default DetailItem ;
