import React, { useState, useEffect } from "react";
import CommonModal from "components/Common/CommonModal";
import FormValidationError from "components/FormValidationError";
import SelectInput from "components/SelectInput";
import userAvatar from "../../../assets/images/png/userAvatar.png";
import "./EditTeamModal.scss";
import { PERMISIONS } from "constants";
import { useGlobalContext } from "context/GlobalContext";

const EditTeamModal = ({
  isOpen,
  onRequestClose,
  onApply,
  title,
  data,
  roles,
  designSystems,
}) => {
  // const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedRole, setSelectedRole] = useState(
    roles.find((role) => role.value == data.role_id)
  );
  const [permission, setPermission] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    setSelectedRole(roles.find((role) => role.value == data.role_id));
    setPermission(data.permission);
  }, [data]);

  useEffect(() => {
    permission?.map((perm) => {
      setFormData({
        ...formData,
        [perm.designSystemId]: perm.access_id,
      });
    });
  }, [permission]);

  const handleRoleChange = (value) => {
    setSelectedRole(value);
  };
  const handleAccessChange = (value, designSystemId) => {
    setFormData({
      ...formData,
      [designSystemId]: value.value,
    });
  };

  const handleApply = () => {
    let input = [];
    let isError = false;

    designSystems.map((ds) => {
      if (formData[ds.id]) {
        let obj = { designSystemId: ds.id, access_id: formData[ds.id] };
        input.push(obj);
      }
    });

    //  "admin role" allow all design system in this domain
    if (selectedRole.value === 1) {
      designSystems.map((ds) => {
        let obj = { designSystemId: ds.id, access_id: 1 };
        input.push(obj);
      });
    }
    if (!isError) {
      onApply(input, selectedRole.value);
      setErrors({});
    }
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      footerClassName='plt'
      onCancelText='Cancel'
      onApplyText='Save'
      bodyClassName='overflow-unset'
    >
      <div className='plt-modal-body-content'>
        <div className='plt-profile-box d-flex align-items-center dis-p-24 dis-pt-16 dis-pb-16'>
          <img
            src={userAvatar}
            alt='profile'
            className='plt-profile-box-image dis-mr-12'
            style={{
              backgroundSize: "cover",
              borderRadius: "50% 50% 50% 50%",
              width: "40px",
              height: "40px",
            }}
          />

          <div className='plt-profile-box-info'>
            {/* <p className='plt-profile-name plt-body-compact-sm plt-font-color-primary dis-mb-4'>
              Jim Jimbo
            </p> */}
            <p className='plt-profile-mail plt-body-compact-xs plt-font-color-primary'>
              {data?.email}
            </p>
          </div>
        </div>
        <div className='plt-select-dropdown-wrapper dis-pl-24 dis-pr-24'>
          <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular font-weight-500 dis-mb-4'>
            Role
          </h6>
          <div className='plt-multi-dropdown-wrapper'>
            <SelectInput
              options={roles}
              defaultValue={selectedRole ?? "Select role..."}
              onSelect={(selectedValue) => handleRoleChange(selectedValue)}
            />
          </div>
        </div>
        {selectedRole?.value !== 1 && (
          <div className='plt-select-dropdown-wrapper dis-mt-16 dis-pl-24 dis-pr-24'>
            <h6 className='plt-select-dropdown-title plt-ui-text-md-medium dis-mb-4'>
              Files with access
            </h6>
            <ul className='plt-file-group w-100'>
              {designSystems.map((designSystem) => (
                <li
                  className='plt-file-list d-flex align-items-center'
                  key={designSystem.id}
                >
                  {/* <div className='plt-member-img'>
                  <img
                    src={userAvatar}
                    alt='profile'
                    className='plt-member-image'
                    style={{
                      backgroundSize: "cover",
                      borderRadius: "50% 50% 50% 50%",
                      width: "40px",
                      height: "40px",
                    }}
                  />
                </div> */}
                  <h3 className='plt-ui-text-md-medium font-weight-400 dis-ml-12'>
                    {designSystem.design_system_name}
                  </h3>
                  <div className='plt-select-dropdown-secondary ml-auto'>
                    <SelectInput
                      options={PERMISIONS}
                      defaultValue={PERMISIONS.find(
                        (p) =>
                          p.value ===
                          permission?.find(
                            (per) => per.designSystemId == designSystem.id
                          )?.access_id
                      )}
                      onSelect={(selectedValue) =>
                        handleAccessChange(selectedValue, designSystem.id)
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
            {errors?.access && <FormValidationError error={errors?.access} />}
          </div>
        )}
      </div>
    </CommonModal>
  );
};

export default EditTeamModal;
