import React from "react";
import "./TabNavigation.scss";

const TabNavigation = ({ tabs, activeIndex, onTabClick, title }) => {
  return (
    <div className="plt-token-contents-doc">
      <h5 className="plt-token-contents-title plt-heading-xs plt-font-color-primary">
        {title}
      </h5>
      <ul className="plt-token-contents-lists">
        {tabs.map((tab, index) => (
          <li
            key={tab}
            className={`plt-token-contents-item plt-body-compact-xs ${
              index === activeIndex ? "active" : ""
            }`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabNavigation;
