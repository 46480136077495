import { useState, useEffect, useLayoutEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import { fetchVariables } from "../api";
import { parseCssVariables } from "../helpers";
import { getDomainAdminId } from "./storageUtils";

const useApplyDesignSystemStylesheet = () => {
  const { setLoading, setResetTokens, resetTokens, pusherIsLoading } =
    useGlobalContext();

  const params = useParams();
  const location = useLocation();
  const [cssVariables, setCssVariables] = useState({});

  useLayoutEffect(() => {
    setLoading(true);
    // const adminId = JSON.parse(localStorage.getItem("user"))?.id;
    const adminId = getDomainAdminId();

    const designSystemId = params.id;

    const getVariables = async (input) => {
      try {
        const data = await fetchVariables(input);
        const variables = parseCssVariables(data);
        setCssVariables(variables);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
        setResetTokens(false);
      }
    };
    if (!pusherIsLoading) {
      getVariables("" + adminId + designSystemId);
    }
  }, [location.pathname, resetTokens, pusherIsLoading]);

  useLayoutEffect(() => {
    const root = document.documentElement;
    for (const [name, value] of Object.entries(cssVariables)) {
      root.style.setProperty(`--${name}`, value);
    }
  }, [cssVariables]);

  return null;
};

export default useApplyDesignSystemStylesheet;
