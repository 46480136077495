import React, { useEffect, useState } from "react";
import "assets/scss/pages/DesignSystem.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { useGlobalContext } from "context/GlobalContext";
import CommonEditor from "components/CommonEditor";
import { useParams } from "react-router-dom";

const ContentPage = () => {
  const { id } = useParams();
  const [page, setPage] = useState("");
  const [pages, setPages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [bannerImage, setBannerImage] = useState("");
  const { designSystemMeta } = useGlobalContext();

  useEffect(() => {
    if (designSystemMeta?.pages) {
      const contentPages = designSystemMeta.pages.filter(
        (page) => page.type == "content"
      );
      setPages(contentPages);

      const currentPage = designSystemMeta.pages.find(
        (page) => page.type == "content"
      );
      setPage(currentPage);
    }
  }, [designSystemMeta]);

  const bannerData = {
    heading: designSystemMeta?.design_system_name,
    description:
      "Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
    bannerImage: bannerImage,
  };
  let sideTabs = pages?.map((page) => {
    return { title: page.name, link: `/${id}/content/${page.id}` };
  });

  const tabs = ["Content"];

  return (
    <DesignSystemLayout
      designSystemName={designSystemMeta?.design_system_name}
      bannerData={bannerData}
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      setBannerImage={setBannerImage}
      sideTabs={sideTabs}
      title='Content'
    >
      <CommonEditor page={page} />
    </DesignSystemLayout>
  );
};

export default ContentPage;
