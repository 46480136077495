import React, { useState } from "react";
import { useParams } from "react-router-dom";

import "assets/scss/pages/DesignSystemTypography.scss";
import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import Character from "components/DesignSystemPage/Typography/Character";
import ScaleToken from "components/DesignSystemPage/Typography/ScaleToken";
import { getStylesSideTabData } from "assets/data/sideTabData";
import TypoUsage from "components/DesignSystemPage/Typography/Usage";
import { useGlobalContext } from "context/GlobalContext";

const DesignSystemTypography = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState(0);

  const bannerData = {
    heading: "Typography",
    description:
      "Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Character", "Type scale and tokens", "Usage"];

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={tabs}
      sideTabs={getStylesSideTabData(id)}
      title='Foundation'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && <Character setSelectedTab={setSelectedTab} />}
        {selectedTab === 1 && <ScaleToken />}
        {selectedTab === 2 && <TypoUsage />}
      </div>
    </DesignSystemLayout>
  );
};

export default DesignSystemTypography;
