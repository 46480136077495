import React from "react";
import "./UsageCardImg.scss";
import guidelinesImg from "../../../assets/images/png/guidelinesImg.png";

// import EditButton from "../../Buttons/EditButton";

const UsageCardImg = ({ UsageBoxData }) => {
  return (
    <div className="plt-usage-card-item usage-img-card-item">
    <span className={`d-block plt-badge plt-badge-status plt-body-compact-md font-weight-500 dis-mb-8 ${UsageBoxData.statusClass}`}>{UsageBoxData.status}</span>
       <div  className="plt-common-card dis-p-16">
            <div className="plt-common-card-img-content d-flex align-items-center justify-content-center h-full">
              <img src={guidelinesImg} alt="guidelinesimage" />
            </div>
      </div>
      <p className="usage-img-card-desc dis-mt-12 plt-body-md">{UsageBoxData.content}</p>
    </div>

  );
};

export default UsageCardImg;
