import React, { useState, useEffect } from "react";
import "./CopyButton.scss";
import { CopyButtonicon, TickIcon } from "components/Icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 5000); // 5 seconds
    }
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <button className='plt-iconbtn copy-btn'>
        <span className='plt-btn-icon'>
          {copied ? <TickIcon /> : <CopyButtonicon />}
        </span>
      </button>
    </CopyToClipboard>
  );
};

export default CopyButton;
