import React, { useState } from "react";
import "./PreviewToasterComponent.scss";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../../components/Common/ComponentCard";
import PrimaryButton from "../../../Common/PrimaryButton";
import "react-loading-skeleton/dist/skeleton.css";
import { EditIcon, TickIcon, ToasterCloseIcon } from "../../../Icons";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";

const ToasterDisplay = ({
  prefix,
  closable,
  action,
  actionFooter,
  onClose,
}) => (
  <div className="dss-toaster">
    {prefix && <TickIcon />}
    <div
      className={`dss-toaster-msg-container ${actionFooter ? "dss-toaster-action-footer" : ""
        }`}
    >
      <h6 className="dss-toaster-msg">Message</h6>
      {action && (
        <PrimaryButton
          className="dss-btn dss-btn-link dss-btn-xs w-fit"
          variant="link"
          text="Action"
        />
      )}
    </div>
    {closable && (
      <span onClick={onClose} className="dss-toaster-close-icon">
        <ToasterCloseIcon />
      </span>
    )}
  </div>
);

const PreviewToasterComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
  const [showToasterConfig, setShowToasterConfig] = useState(null);

  const handleButtonClick = (config) => {
    setShowToasterConfig(config);
    setTimeout(() => {
      setShowToasterConfig(null);
    }, 5000);
  };

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  const commonDesc =
    "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container.";
  const toasterConfigurations = [
    { title: "Normal", desc: commonDesc },
    { title: "Normal with leading icon", prefix: true, desc: commonDesc },
    { title: "Normal with closable icon", closable: true, desc: commonDesc },
    {
      title: "Normal with leading and closable icon",
      prefix: true,
      closable: true,
      desc: commonDesc,
    },
    { title: "Normal with actions", action: true, desc: commonDesc },
    {
      title: "Normal with actions and leading icon",
      action: true,
      prefix: true,
      desc: commonDesc,
    },
    {
      title: "Normal with actions and closable icon",
      action: true,
      closable: true,
      desc: commonDesc,
    },
    {
      title: "Normal with actions, leading and closable icon",
      action: true,
      prefix: true,
      closable: true,
      desc: commonDesc,
    },
    {
      title: "Normal with wrapped actions",
      action: true,
      actionFooter: true,
      desc: commonDesc,
    },
    {
      title: "Normal with leading icon and wrapped actions",
      action: true,
      prefix: true,
      actionFooter: true,
      desc: commonDesc,
    },
    {
      title: "Normal with wrapped actions and closable icon",
      action: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
    },
    {
      title: "Normal with leading icon, wrapped actions and closable icon",
      action: true,
      prefix: true,
      actionFooter: true,
      closable: true,
      desc: commonDesc,
    },
  ];

  const previewtoasterData = [
    { title: "Normal with leading icon", prefix: true, desc: commonDesc },
  ];

  const [isModalVisible, setModalVisibility] = useState(false);
  const showModal = () => {
    setModalVisibility(true);
  };

  const buttonStates = ["Default", "Hovered", "Pressed", "Disabled"];
  const [buttonState, setButtonState] = useState(buttonStates[0]);

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        {toasterConfigurations.map((config) => (
          <div key={config.title} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {config.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {commonDesc}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              {/* 
            <PrimaryButton
                text={"Show Toaster"}
                className="dss-btn dss-btn-primary"
                onClick={() => handleButtonClick(config)}
              /> */}
              <ToasterDisplay
                prefix={config?.prefix}
                closable={config?.closable}
                action={config?.action}
                actionFooter={config?.actionFooter}
              // onClose={() => setShowToasterConfig(null)}
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}

        {showToasterConfig && (
          <ToasterDisplay
            prefix={showToasterConfig.prefix}
            closable={showToasterConfig.closable}
            action={showToasterConfig.action}
            actionFooter={showToasterConfig.actionFooter}
            onClose={() => setShowToasterConfig(null)}
          />
        )}
      </div>
      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={buttonStates}
                  selectedOption={buttonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            {previewtoasterData.map((data, index) => (
              <PrimaryButton
                text={"Show Toaster"}
                className="dss-btn dss-btn-primary"
                onClick={() => handleButtonClick(data)}
              />
            ))}
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Background color
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                background.button.{buttonState.toLowerCase()}.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("bg");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Typography
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                uiText.lg.medium
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setTypoTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Text color
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                text.button.fill.primary
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => {
                  setTokenType("text");
                  setColorTokenModalOpen(true);
                }}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Radius
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                radius-150
              </p>
              <span
                className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon"
                onClick={() => setRadiusTokenModalOpen(true)}
              >
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewToasterComponent;
