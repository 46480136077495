import React, { useEffect } from "react";
import Pusher from "pusher-js";
import { useGlobalContext } from "../../context/GlobalContext";
import { getDomainAdminId } from "../../hooks/storageUtils";

const PusherComponent = () => {
  const { pusherIsLoading, setPusherSheetIsLoading } = useGlobalContext();

  useEffect(() => {
    setPusherSheetIsLoading(true); // Start loading when component mounts

    const adminId = getDomainAdminId();
    const pusherKey = process.env.REACT_APP_PUSHER_API;
    const userId = adminId;

    Pusher.logToConsole = false;

    const pusher = new Pusher(pusherKey, {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("build-channel");
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        if (data.user_id === userId) {
          setPusherSheetIsLoading(false);
        }
      }
    );
    setPusherSheetIsLoading(false);

    return () => {
      channel.unbind(
        "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated"
      );
      pusher.unsubscribe("build-channel");
      setPusherSheetIsLoading(false);
    };
  }, [setPusherSheetIsLoading]);

  return <div></div>;
};

export default PusherComponent;
