import React from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import { downloadInvoice } from "api";
import { useGlobalContext } from "context/GlobalContext";

const SubscriptionTableRaw = ({ data }) => {
  const { setLoading } = useGlobalContext();

  const handleDownload = async (id) => {
    try {
      setLoading(true);
      const response = await downloadInvoice(id);
      fileDownload(response, `invoice-#${id}.pdf`);
    } catch (error) {
      console.error("Failed to download invoice", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <tr>
      <td>
        <div className='plt-color-token-name d-flex align-items-center'>
          <p className='plt-color-description plt-body-md plt-font-color-secondary'>
            {data?.id}
          </p>
          <div className='plt-table-hover-icon dis-ml-8'></div>
        </div>
      </td>
      <td>
        <p className='plt-color-description plt-body-md plt-font-color-secondary'>
          {moment(data?.invoice_date).format("ll")}
        </p>
      </td>
      <td>
        <div className='plt-color-column d-flex'>
          <div className='plt-color-detail plt-body-md plt-font-color-secondary'>
            {data?.amount}
          </div>
        </div>
      </td>
      <td>
        <div className='plt-color-token-name d-flex align-items-center'>
          <span
            className='plt-modal-link plt-ui-text-sm-medium'
            style={{ cursor: "pointer" }}
            onClick={() => handleDownload(data.id)}
          >
            Download
          </span>
        </div>
      </td>
    </tr>
  );
};

export default SubscriptionTableRaw;
