import React, { useState } from "react";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getPatternsSideData } from "../../assets/data/sideTabData";

// import UsageButtonComponent from "../../components/DesignSystemPage/ButtonComponent/UsageButtonComponent";
// import TokenButtonComponent from "../../components/DesignSystemPage/ButtonComponent/TokenButtonComponent";
// import PreviewButtonComponent from "../../components/DesignSystemPage/ButtonComponent/PreviewButtonComponent";
import { useGlobalContext } from "context/GlobalContext";
import OverviewFormPattern from "components/DesignSystemPage/FormPattern/OverviewFormPattern";

const DesignSystemForms = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState(0);

  const bannerData = {
    heading: "Forms",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Overview"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return <OverviewFormPattern />;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getPatternsSideData(id)}
      title='Patterns'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default DesignSystemForms;
