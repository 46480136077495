import { useGlobalContext } from "context/GlobalContext";
import { useState, useEffect } from "react";
import TypographyTable from "./../../components/DesignSystemPage/Typography/ScaleToken/TypographyTable/index";
import { uiTextTokens } from "api";

const useTokensData = () => {
  const { designSystem } = useGlobalContext();
  const [tokensData, setTokensData] = useState([]);

  useEffect(() => {
    if (designSystem) {
      const createTokenData = (
        bg,
        text,
        spacing,
        radius,
        typography,
        border, elevation, underline
      ) => ({
        bg: {
          className: bg?.className,
          key: bg?.key,
          tokenName: bg?.tokenName,
          type: bg?.type,
          value: bg?.value,
          varName: bg?.varName,
        },
        text: {
          className: text?.className,
          key: text?.key,
          tokenName: text?.tokenName,
          type: text?.type,
          value: text?.value,
          varName: text?.varName,
        },
        spacing: {
          normal: {
            className: spacing?.normal?.className,
            key: spacing?.normal?.key,
            tokenName: spacing?.normal?.tokenName,
            type: spacing?.normal?.type,
            value: spacing?.normal?.value,
            varName: spacing?.normal?.varName,
          },
          dense: {
            className: spacing?.dense?.className,
            key: spacing?.dense?.key,
            tokenName: spacing?.dense?.tokenName,
            type: spacing?.dense?.type,
            value: spacing?.dense?.value,
            varName: spacing?.dense?.varName,
          },
          large: {
            className: spacing?.large?.className,
            key: spacing?.large?.key,
            tokenName: spacing?.large?.tokenName,
            type: spacing?.large?.type,
            value: spacing?.large?.value,
            varName: spacing?.large?.varName,
          },
        },
        radius: {
          normal: {
            className: radius?.normal?.className,
            key: radius?.normal?.key,
            tokenName: radius?.normal?.tokenName,
            type: radius?.normal?.type,
            value: radius?.normal?.value,
            varName: radius?.normal?.varName,
          },
          dense: {
            className: radius?.dense?.className,
            key: radius?.dense?.key,
            tokenName: radius?.dense?.tokenName,
            type: radius?.dense?.type,
            value: radius?.dense?.value,
            varName: radius?.dense?.varName,
          },
          large: {
            className: radius?.large?.className,
            key: radius?.large?.key,
            tokenName: radius?.large?.tokenName,
            type: radius?.large?.type,
            value: radius?.large?.value,
            varName: radius?.large?.varName,
          },
        },
        typography: {
          normal: {
            className: typography?.normal?.className,
            key: typography?.normal?.key,
            tokenName: typography?.normal?.tokenName,
            type: typography?.normal?.type,
            value: typography?.normal?.value,
            varName: typography?.normal?.varName,
          },
          dense: {
            className: typography?.dense?.className,
            key: typography?.dense?.key,
            tokenName: typography?.dense?.tokenName,
            type: typography?.dense?.type,
            value: typography?.dense?.value,
            varName: typography?.dense?.varName,
          },
          large: {
            className: typography?.large?.className,
            key: typography?.large?.key,
            tokenName: typography?.large?.tokenName,
            type: typography?.large?.type,
            value: typography?.large?.value,
            varName: typography?.large?.varName,
          },
        },
        border: {
          className: border?.className,
          key: border?.key,
          tokenName: border?.tokenName,
          type: border?.type,
          value: border?.value,
          varName: border?.varName,
        },
        elevation: {
          normal: {
            className: elevation?.pressedNormal?.className,
            key: elevation?.pressedNormal?.key,
            tokenName: elevation?.pressedNormal?.tokenName,
            type: elevation?.pressedNormal?.type,
            value: elevation?.pressedNormal?.value,
            varName: elevation?.pressedNormal?.varName,
          },
          dense: {
            className: elevation?.pressedDense?.className,
            key: elevation?.pressedDense?.key,
            tokenName: elevation?.pressedDense?.tokenName,
            type: elevation?.pressedDense?.type,
            value: elevation?.pressedDense?.value,
            varName: elevation?.pressedDense?.varName,
          },
          large: {
            className: elevation?.pressedLarge?.className,
            key: elevation?.pressedLarge?.key,
            tokenName: elevation?.pressedLarge?.tokenName,
            type: elevation?.pressedLarge?.type,
            value: elevation?.pressedLarge?.value,
            varName: elevation?.pressedLarge?.varName,
          },

        },
        underline: {
          className: underline?.className,
          key: underline?.key,
          tokenName: underline?.tokenName,
          type: underline?.type,
          value: underline?.value,
          varName: underline?.varName,
        },
      });


      const data = [
        {
          primary: {
            default: createTokenData(
              designSystem?.light?.background?.button?.fill?.primary,
              designSystem?.light?.text?.button?.fill?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.fill?.primary,
              designSystem?.light?.shadow?.button?.fill
            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.fill?.hovered,
              designSystem?.light?.text?.button?.fill?.hovered,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.fill?.hovered, designSystem?.light?.shadow?.button?.fill, designSystem?.light?.shadow?.button?.fill


            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.fill?.pressed,
              designSystem?.light?.text?.button?.fill?.pressed,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.fill?.pressed, designSystem?.light?.shadow?.button?.fill

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.fill?.disabled,
              designSystem?.light?.text?.button?.fill?.disabled,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.fill?.disabled, designSystem?.light?.shadow?.button?.fill

            ),
          },
        },
        {
          danger: {
            default: createTokenData(
              designSystem?.light?.background?.button?.danger?.primary,
              designSystem?.light?.text?.button?.danger?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.shadow?.button?.fill

            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.danger?.hovered,
              designSystem?.light?.text?.button?.danger?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.shadow?.button?.fill

            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.danger?.pressed,
              designSystem?.light?.text?.button?.danger?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.shadow?.button?.fill

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.danger?.disabled,
              designSystem?.light?.text?.button?.danger?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.shadow?.button?.fill
            ),
          },
        },
        {
          default: {
            default: createTokenData(
              designSystem?.light?.background?.button?.default?.primary,
              designSystem?.light?.text?.button?.default?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.default?.primary,
              designSystem?.light?.shadow?.button?.fill

            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.default?.hovered,
              designSystem?.light?.text?.button?.default?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.default?.hovered,
              designSystem?.light?.shadow?.button?.fill

            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.default?.pressed,
              designSystem?.light?.text?.button?.default?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.default?.pressed,
              designSystem?.light?.shadow?.button?.fill

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.default?.disabled,
              designSystem?.light?.text?.button?.default?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.default?.disabled,
              designSystem?.light?.shadow?.button?.fill

            ),
          },
        },
        {
          ghost: {
            default: createTokenData(
              designSystem?.light?.background?.button?.ghost?.primary,
              designSystem?.light?.text?.button?.ghost?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.ghost?.primary,
              designSystem?.light?.shadow?.button?.fill

            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.ghost?.hovered,
              designSystem?.light?.text?.button?.ghost?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.ghost?.hovered,
              designSystem?.light?.shadow?.button?.fill

            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.ghost?.pressed,
              designSystem?.light?.text?.button?.ghost?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.ghost?.pressed,
              designSystem?.light?.shadow?.button?.fill

            ),
            iconFill: createTokenData(
              designSystem?.light?.background?.button?.ghost?.iconFill,
              designSystem?.light?.text?.button?.ghost?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.ghost?.primary,
              designSystem?.light?.shadow?.button?.fill

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.ghost?.disabled,
              designSystem?.light?.text?.button?.ghost?.disabled,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.ghost?.disabled,
              designSystem?.light?.shadow?.button?.fill

            ),
          },
        },
        {
          stroke: {
            default: createTokenData(
              designSystem?.light?.background?.button?.stroke?.primary,
              designSystem?.light?.text?.button?.stroke?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.stroke?.primary,
              designSystem?.light?.shadow?.button?.stroke

            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.stroke?.hovered,
              designSystem?.light?.text?.button?.stroke?.hovered,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.stroke?.hovered,
              designSystem?.light?.shadow?.button?.stroke

            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.stroke?.pressed,
              designSystem?.light?.text?.button?.stroke?.pressed,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.stroke?.hovered,
              designSystem?.light?.shadow?.button?.stroke

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.stroke?.disabled,
              designSystem?.light?.text?.button?.stroke?.disabled,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.stroke?.disabled,
              designSystem?.light?.shadow?.button?.stroke

            ),
          },
        },
        {
          link: {
            default: createTokenData(
              designSystem?.light?.background?.button?.link?.primary,
              designSystem?.light?.text?.button?.link?.primary,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.link?.primary,
              designSystem?.light?.shadow?.button?.link

            ),
            hovered: createTokenData(
              designSystem?.light?.background?.button?.link?.hovered,
              designSystem?.light?.text?.button?.link?.hovered,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.link?.hovered,
              designSystem?.light?.shadow?.button?.link

            ),
            pressed: createTokenData(
              designSystem?.light?.background?.button?.link?.pressed,
              designSystem?.light?.text?.button?.link?.pressed,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.link?.hovered,
              designSystem?.light?.shadow?.button?.link

            ),
            disabled: createTokenData(
              designSystem?.light?.background?.button?.link?.disabled,
              designSystem?.light?.text?.button?.link?.disabled,
              designSystem?.light?.spacing?.button,
              designSystem?.light?.borderRadius?.button,
              designSystem?.light?.uiText?.button,
              designSystem?.light?.border?.button?.link?.disabled,
              designSystem?.light?.shadow?.button?.link
            ),
          },
        },
      ];
      setTokensData(data);
    }
  }, [designSystem]);

  return tokensData;
};

export default useTokensData;
