import React from "react";
import "./RadioComponent.scss";

const RadioComponent = ({
  options,
  name,
  checkedValue,
  onChange,
  size,
  withLabel = false,
  variant,
  typo,
}) => {
  return (
    <div className={`dss-custom-radio-group ${size}`}>
      {options.map((option, index) => (
        <label
          key={index}
          className={`dss-radio-wrapper ${checkedValue === option.value ? "checked" : ""
            } ${withLabel ? "with-label" : ""}`}
        >
          <input
            className={`dss-radio-input dss-radio-input-${variant}`}
            type="radio"
            name={name}
            value={option.value}
            checked={checkedValue === option.value}
            onChange={onChange}
            disabled={option.disabled}
          />
          {withLabel && <span className={`dss-radio-label dss-${typo} dss-radio-label-${variant}`}>{option.label}</span>}
        </label>
      ))}
    </div>
  );
};

export default RadioComponent;
