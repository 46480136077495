import React, { useRef, useState } from "react";
import "./Inputs.scss";
import { ButtonIconSample, ErrorIcon } from "../Icons";
import Tag from "components/Tag"

const Inputs = ({
  placeholder = "value",
  disabled,
  readOnlyState,
  denseClassName,
  className,
  value,
  inputId,
  readOnly,
  tailingIcon,
  leadingIcon,
  prefix,
  suffix,
  fluid,
  validation = true,
  tag = false
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const handleFocus = () => {
    if (!disabled && !tag) {
    // if (!disabled) {
      setFocus(true);
      inputRef?.current?.focus();
    }
  };
  const handleBlur = () => {
    setFocus(false);
    if (validation && inputValue == null) {
      setError(true);
    } else if (validation) {
      validateInput(inputValue);
    }
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (validation) {
      validateInput(e.target.value);
    }
  };
  const validateInput = (value) => {
    if (value.length < 1) {
      setError("Input must be at least 5 characters long");
    } else {
      setError(null);
    }
  };
  return (
    <div
      className={`dss-input-wrapper dss-input ${denseClassName} ${className ? className : ''} ${fluid ? "dss-input-fluid" : ""}  ${disabled ? "disabled" : ""} ${readOnlyState ? "read-only" : ""}`}>

      {!fluid && (
        <label htmlFor={inputId} className="dss-input-label">
          Label
        </label>
      )}
      <div
        onClick={handleFocus}
        className={`dss-input-container ${className ? className : ''} ${inputValue?.length ? 'has-value' : ''} ${isFocus ? "focused" : "blur"} ${error ? "error" : ''}
        ${prefix ? 'prefix' : ''} ${leadingIcon ? 'leadingicon' : ''} ${tag ? 'withtag' : ''}
        `}
      >

        {leadingIcon == true ? (
          <span className="dss-input-icon">
            <ButtonIconSample color="#171717" width="16px" height="16px" />
          </span>
        ) : (
          leadingIcon
        )}
        {fluid && (
          <label htmlFor={inputId} className="dss-input-label">
            Label
          </label>
        )}
        {prefix == true ? <p className="dss-prefix-text">Prefix</p> : prefix}
        {tag ? (
          <Tag
            size="small"
            typo="ui-text-xs-medium"
            prefixIcon={true}
            statusIcon={false}
            mode={"closable"}
          />
        ) : (
          <input
            ref={inputRef}
            readOnly={readOnly || tag}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            id={inputId}
            value={inputValue}
            disabled={disabled}
            type="text"
            placeholder={fluid ?'': placeholder}
            className="dss-input-field"
          />
        )}
        <div className="d-flex ml-auto">
          {suffix == true ? <p className="dss-suffix-text">Suffix</p> : suffix}
          {tailingIcon == true ? (
            <span className="dss-input-icon dis-ml-8">
              <ButtonIconSample color="#171717" width="16px" height="16px" />
            </span>
          ) : (
            tailingIcon
          )}
        </div>
      </div>
      <div className={`dss-error-container ${error && "active"}`}>
        <span className="dss-error-icon">
          <ErrorIcon />
        </span>
        <h6 className="dss-error-message">Helpful validation message</h6>
      </div>
    </div>
  );
};

export default Inputs;
