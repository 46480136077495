import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

//Fetch description or page data
async function fetchPage(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(
      `${apiUrl}/pages/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//page create
async function createPage(data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/pages`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//page update
async function updatePage(data, id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${apiUrl}/pages/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in updateToken:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
//rename page
async function renamePage(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/rename-page/${id}`,
      { name: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in rename page:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//rename page
async function deletePage(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`${apiUrl}/pages/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in delete page:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { fetchPage, createPage, updatePage, renamePage, deletePage };
