import SettingsLayout from "components/layouts/SettingsLayout";
import React from "react";
import Account from "./settings/Account";

const Settings = () => {
  return (
    <SettingsLayout>
      <Account />
    </SettingsLayout>
  );
};

export default Settings;
