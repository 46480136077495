import CircularLoader from "components/Common/CircularLoader/CircularLoader";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { getUser } from "hooks/storageUtils";
import { NameInitialsAvatar } from "react-name-initials-avatar";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

const Test = () => {
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  const showToast = (type) => {
    if (type === 1) {
      toast.success("Success");
    } else if (type === 2) {
      toast.warning("warning");
    } else if (type === 3) {
      toast.error("error");
    }
  };

  const user = getUser();
  return (
    <div>
      <ToastContainer />
      <button className='plt-btn plt-btn-primary' onClick={() => showToast(1)}>
        Success
      </button>
      <button
        className='plt-btn plt-btn-secondary'
        onClick={() => showToast(2)}
      >
        Warning
      </button>
      <button className='plt-btn plt-btn-danger' onClick={() => showToast(3)}>
        Error
      </button>
      <CircularLoader percentage='40' />
      <NameInitialsAvatar
        bgColor='#630B99'
        borderColor='#630B99'
        textColor='white'
        name={user?.name?.toUpperCase()}
      />
      <br />
      <br />
      <br />
      <br />
      <ReactMultiEmail
        placeholder='Input your email'
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        autoFocus={true}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      <br />
      <h4>react-multi-email value</h4>
      <h3>Focused: {focused ? "true" : "false"}</h3>
      <p>{emails.join(", ") || "empty"}</p>
    </div>
  );
};

export default Test;
