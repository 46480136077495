import React from "react";
import "./CheckboxComponent.scss";

const CheckboxComponent = ({ variant, size, checked, disabled, label, typo, onChange }) => {
  return (
    <div className="code-box-content w-100">
      <div className="d-flex justify-content-center">
        <form>
          <div className="dss-custom-checkbox dis-pl-12">
            <div className={`dss-checkbox-wrapper`}>
              <input
                disabled={disabled}
                checked={checked}
                className={`dss-checkbox dss-checkbox-${variant} dss-checkbox-${size}`}
                type="checkbox"
                name={`${variant}-${size}`}
                id={`${variant}-${size}`}
                onChange={onChange}
              />
              {label && (
                <label
                  htmlFor={`${variant}-${size}`}
                  className={`dss-checkbox-label dss-checkbox-label-${size} dss-${typo} dss-checkbox-label-${variant}`}
                >
                  {label}
                </label>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckboxComponent;
