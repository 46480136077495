// storageUtils.js

const STORAGE_CHANGE_EVENT = "localStorageChange";

const dispatchStorageChangeEvent = () => {
  window.dispatchEvent(new Event(STORAGE_CHANGE_EVENT));
};

/**
 * Fetches the admin ID from local storage.
 * @returns {string|null} The admin ID if found, otherwise null.
 */

export function getAdminId() {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.id || null;
  } catch (error) {
    console.error("Failed to retrieve admin ID from local storage:", error);
    return null;
  }
}
/**
 * Fetches the current domain admin id.
 * @returns {string|null} The admin ID if found, otherwise null.
 */

export function getDomainAdminId() {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.current_domain?.admin_id;
  } catch (error) {
    console.error("Failed to retrieve admin ID from local storage:", error);
    return null;
  }
}
export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

function pushOrUpdate(tokens, key, value) {
  let index = tokens.findIndex((item) => item.key === key);
  if (index !== -1) {
    tokens[index].value = value;
  } else {
    tokens.push({ key, value });
  }
  return tokens;
}

export const addToLocalStorage = (tokens) => {
  const currentTokens = JSON.parse(localStorage.getItem("tokens")) ?? [];
  let newTokens = [];
  if (currentTokens) {
    tokens.map((token) => {
      newTokens = pushOrUpdate(currentTokens, token.key, token.value);
    });
  } else {
    newTokens = tokens;
  }
  localStorage.setItem("tokens", JSON.stringify(newTokens));
  dispatchStorageChangeEvent();
};

export const checkForPublishing = () => {
  let check =
    localStorage.getItem("tokens") &&
    localStorage.getItem("tokens").length !== 0;
  return check;
};

export const getTokensFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("tokens"));
};

export const removeTokensfromLocalStorage = () => {
  localStorage.removeItem("tokens");
  dispatchStorageChangeEvent();
};
