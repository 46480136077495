import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

//Fetch description or page data
async function downloadInvoice(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/invoice-pdf/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch invoice pdf:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { downloadInvoice };
