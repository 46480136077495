import React, { useState } from "react";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import "react-loading-skeleton/dist/skeleton.css";
import { LeftArrow, RightArrow } from "../../../Icons";
import "./PreviewPaginationComponent.scss";


const PaginationDisplay = ({
  alerttype,
  title
}) => (
   <div className={`dss-alert dss-${alerttype}`}>
      <ul className="pagination d-flex">
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium"><LeftArrow /></span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium">1</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium active">2</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium">3</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium">4</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium">5</span></li>
        <li className="pagination-list"><span className="plt-ui-text-sm-medium">...</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium">10</span></li>
        <li className="pagination-list"><span className="pagination-list-item plt-ui-text-sm-medium"><RightArrow /></span></li>
      </ul> 
  </div>
);

const PreviewPaginationComponents = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
  const [showPaginationConfig, setShowPaginationConfig] = useState(null);

  const handleButtonClick = (config) => {
    setShowPaginationConfig(config);
    setTimeout(() => {
      setShowPaginationConfig(null);
    }, 5000);
  };

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  const commonDesc =
    "Use a primary button to call attention to an action on a form or to highlight the strongest call to action on a page. Primary buttons should only appear once per container.";
  const alertConfigurations = [
    {
      title: "Default",
      desc: commonDesc,
      alerttype: "alert-informative",
    },
    
  ];


  const [isModalVisible, setModalVisibility] = useState(false);
  const showModal = () => {
    setModalVisibility(true);
  };


  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        {alertConfigurations.map((config) => (
          <div key={config.title} className="plt-content-wrapper">
            <div className="plt-content-wrapper-block dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {config.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {commonDesc}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <PaginationDisplay
              />
            </ComponentCard>
            <AllUsage />
          </div>
        ))}

        {showPaginationConfig && (
          <PaginationDisplay
          />
        )}
      </div>
    
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewPaginationComponents;
