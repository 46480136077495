import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../components/layouts/AuthLayout";
import OtpInput from "../components/OtpInput/OTPInput";
import "../assets/scss/pages/_verify.scss";
import { verifyOtp, forgetPassword } from "api";
import { ToastContainer, toast } from "react-toastify";

import { SyncLoader } from "react-spinners";

const VerifyOtp = () => {
  const [timer, setTimer] = useState(119);
  const [canResend, setCanResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      setOtp(token.split(""));
    }

    const intervalId = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          setCanResend(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [token]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleContinue = async () => {
    const request = {
      email: email,
      token: otp.join(""),
    };

    try {
      const data = await verifyOtp(request);
      navigate(`/reset?email=${email}&token=${otp.join("")}`);
    } catch (error) {
      toast.error(error.message);

      console.error("OTP verification failed", error.message);
      // Show error message
    }
  };

  const handleResend = async (event) => {
    event.preventDefault();
    try {
      const response = await forgetPassword(email);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <AuthLayout>
      <div className="verify-container flex flex-column w-full">
        <ToastContainer />

        <h4 className="plt-heading-lg plt-font-color-primary dis-px-auto title">
          Password reset
        </h4>
        <p className="description plt-body-md dis-mt-12 dis-mb-32 d-flex">
          We sent a code to
          <span className="desc plt-body-md plt-font-color-primary dis-ml-4">
            {email}
          </span>
        </p>

        <p className="content-des plt-body-compact-sm dis-pb-4">Secure code</p>

        <OtpInput value={otp} onChange={setOtp} />

        <button
          type="submit"
          className="primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32"
          onClick={handleContinue}
        >
          {isLoading ? (
            <SyncLoader color="#fff" loading={true} width="100%" height="100%" />
          ) : (
            "Continue"
          )}

        </button>

        <div className="resend text-desc plt-body-compact-sm font-weight-500 dis-mt-24">
          Didn’t receive the email?
          {canResend ? (
            <span
              className="text-link plt-body-compact-sm font-weight-500 cursor-pointer dis-ml-8"
              onClick={handleResend}
            >
              Click to resend
            </span>
          ) : (
            <span className="dis-ml-8">Resend in {formatTime()}</span>
          )}
        </div>
      </div>
    </AuthLayout>
  );
};

export default VerifyOtp;
