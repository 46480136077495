import React from "react";
import "./DeleteButton.scss";
import { DeleteButtonicon } from "../../Icons";

const DeleteButton = () => {
  return (
    <button className="plt-iconbtn delete-btn">
      <span className="plt-btn-icon">
        <DeleteButtonicon />
      </span>
    </button>
  );
};

export default DeleteButton;
