import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className='d-flex justify-content-between w-full'>
      <div className='tab-content-container dis-pr-48 dis-pl-48'>
        404
        <div>Page not found</div>
        <div>
          <button className='plt-btn plt-btn-primary' onClick={goHome}>
            Go back home
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
