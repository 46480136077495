const generateFontSizes = (base, scale) => {
  var plusSeries = [base];
  var minusSeries = [base];

  for (var i = 0; i < 6; i++) {
    var nextValue = plusSeries[plusSeries.length - 1] * scale;
    plusSeries.push(nextValue);
  }

  for (var i = 0; i < 2; i++) {
    var nextValue = minusSeries[minusSeries.length - 1] / scale;
    minusSeries.push(nextValue);
    plusSeries.unshift(nextValue);
  }

  var roundedArray = plusSeries.map(function (num) {
    return Math.round(num);
  });
  return roundedArray.reverse();
};

export { generateFontSizes };
