import React, { useState, useEffect } from "react";
import { updatePage } from "../../../api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DOMPurify from "dompurify"; // Import DOMPurify
import "./MainContent.scss";
import PrimaryButton from "../../Common/PrimaryButton";
import DraftEditor from "../../Common/DraftEditor/DraftEditor";
import DesCardImage1 from "../../../assets/images/png/desCardImage-1.png";
import DesCardImage2 from "../../../assets/images/png/desCardImage-2.png";
import DesCardImage3 from "../../../assets/images/png/desCardImage-3.png";
import DesCardImage4 from "../../../assets/images/png/desCardImage-4.png";
import DesCardImage5 from "../../../assets/images/png/desCardImage-5.png";
import EditButton from "../../Buttons/EditButton";
import Tooltip from "components/Tooltip";
import AuthAction from "components/auth/AuthAction";

const MainContent = ({ page }) => {
  const [editorState, setEditorState] = useState(page?.content || "");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (page?.content) {
      setEditorState(page?.content);
      setIsLoading(false);
    }
  }, [page, location]);

  const saveChanges = async () => {
    const contentHtml = editorState;

    const dataToSubmit = {
      name: "Getting Started",
      design_system_id: id,
      content: contentHtml,
    };

    try {
      await updatePage(dataToSubmit, page?.id);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update page:", error.message);
    }
  };

  const handleCardClick = (link) => {
    navigate(`/${id}/${link}`);
  };

  const StyleCard = ({ styleCard }) => (
    <div className='plt-card' onClick={() => handleCardClick(styleCard.link)}>
      <div className='plt-card-image w-100'>
        <img src={styleCard.image} alt={styleCard.name} className='w-100' />
      </div>
      <div className='plt-card-body'>
        <h6 className='plt-card-title plt-heading-sm font-weight-500 plt-font-color-primary dis-mt-16 dis-mb-8'>
          {styleCard.name}
        </h6>
        <p className='plt-card-desc plt-body-md'>{styleCard.content}</p>
      </div>
    </div>
  );

  return (
    <div className='plt-contents-container'>
      {isEditing ? (
        <div>
          <DraftEditor
            editorState={editorState}
            setEditorState={setEditorState}
          />
          <div className='dis-mt-20 dis-mb-40'>
            <PrimaryButton
              onClick={() => setIsEditing(false)}
              text='Cancel'
              className='dss-btn dss-btn-secondary dss-btn-lg dis-mr-20'
            />
            <PrimaryButton
              onClick={saveChanges}
              text='Save'
              className='dss-btn dss-btn-primary dss-btn-lg'
            />
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-between'>
          {isLoading ? (
            <Skeleton count={10} />
          ) : (
            <div
              className='content-display'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(editorState), // Sanitize the HTML content
              }}
            />
          )}
          <AuthAction>
            <span className='dis-ml-24' onClick={() => setIsEditing(true)}>
              <Tooltip position='top' arrow='btm-center' text='Edit content'>
                <EditButton />
              </Tooltip>
            </span>
          </AuthAction>
        </div>
      )}
      <div className='card-wrapper dis-mt-24'>
        <h6 className='contents-head plt-heading-md plt-font-color-primary dis-mb-16'>
          What’s inside?
        </h6>
        <div className='plt-card-container plt-card-inside d-flex'>
          {[
            {
              id: 1,
              name: "Styles",
              content: "Explore your collection...",
              image: DesCardImage1,
              link: "styles/colors",
            },
            {
              id: 2,
              name: "Components",
              content: "From buttons to cards...",
              image: DesCardImage2,
              link: "components/button",
            },
            {
              id: 3,
              name: "Patterns",
              content: "Patterns are to inspire...",
              image: DesCardImage3,
            },
            {
              id: 4,
              name: "Resources",
              content: "Find guides, templates...",
              image: DesCardImage4,
            },
            {
              id: 5,
              name: "All Tokens",
              content: "Design tokens provide...",
              image: DesCardImage5,
              link: "styles/tokens",
            },
          ].map((styleCard) => (
            <StyleCard key={styleCard.id} styleCard={styleCard} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
