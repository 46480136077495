import { joinInvite } from "api";
import { checkJoinInvite } from "api";
import RouteLoader from "components/RouteLoader";
import { useGlobalContext } from "context/GlobalContext";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const JoinDomain = () => {
  const navigate = useNavigate();
  const { invite_token } = useParams();
  const { setLoading } = useGlobalContext();
  const [invite, setInvite] = useState("");

  useEffect(() => {
    const checkInvite = async () => {
      try {
        setLoading(true);
        const { data } = await checkJoinInvite(invite_token);
        setInvite(data);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      } finally {
        setLoading(false);
      }
    };
    checkInvite();
  }, []);

  const handleJoin = async () => {
    try {
      const { data } = await joinInvite(invite.id);
      if (data) navigate("/dashboard");
    } catch (error) {
      console.error("Failed to join invite", error);
    }
  };

  let content;
  if (!invite) {
    content = <div>Invite not found</div>;
  } else {
    content = (
      <div>
        You have received to join in {invite?.admin?.companyname}
        <div className='dis-mt-20 dis-mb-40'>
          <button onClick={handleJoin}>
            Join {invite?.admin?.companyname}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <RouteLoader>{content}</RouteLoader>
    </>
  );
};

export default JoinDomain;
