import React, { useState } from "react";

import "./ButtonTable.scss"; // Make sure the path matches where your SCSS file is located
// import EditButton from "../../../../Buttons/EditButton";
import CopyButton from "../../../../Buttons/CopyButton";

const ButtonTable = ({ buttonTableData, openModal }) => {
  // const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  return (
    <div className="plt-table-wrapper">
      <div className="plt-table-container">
        <table className="plt-table">
          <thead>
            <tr>
              <th className="plt-heading-xs font-weight-400 plt-font-color-tertiary">
                Element
              </th>
              <th className="plt-heading-xs font-weight-400 plt-font-color-tertiary">
                Token name
              </th>
              <th className="plt-heading-xs font-weight-400 plt-font-color-tertiary">
                Property
              </th>
            </tr>
          </thead>
          <tbody>
            {buttonTableData.map((row, index) => (
              <tr key={index}>
                <td className="plt-body-sm">{row.elements}</td>
                <td className="plt-badge-icon-wrapper  d-flex align-items-center">
                  <p className="plt-badge plt-badge-surface plt-body-compact-sm">
                    {row.tokenName}
                  </p>
                  <div className="plt-table-hover-icon dis-ml-8">
                    {/* {hoveredRowIndex === index && (
                    <div onClick={() => openModal()} className="copy-icon">
                      <CopyButton />
                    </div>
                  )} */}
                    <div className="copy-icon">
                      <CopyButton text={row.tokenName} />
                    </div>
                  </div>
                </td>
                <td className="button-property plt-body-compact-sm">
                  {row.property}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ButtonTable;
