import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../components/layouts/AuthLayout";
import { forgetPassword } from "../api"; // Update the path to api.js
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SyncLoader } from "react-spinners";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    if (value === "") {
      setEmailError("Email is required");
    } else if (!validateEmail(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!validateEmail(email)) {
      setIsLoading(false);
      setEmailError("Invalid email format");
      return;
    }
    try {
      const response = await forgetPassword(email);
      toast.success(response.message);
      if (
        response.message ===
        "Reset Password Mail Send Sucessfully. Please check your Inbox."
      ) {
        navigate("/verify?email=" + email);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <ToastContainer />

      <div className=' register-container w-full h-full '>
        <div className='w-full'>
          <h4 className='plt-heading-lg plt-font-color-primary dis-px-auto'>
            Forgot your password?
          </h4>
          <p className='info-msg plt-body-md plt-font-color-secondary dis-mt-12'>
            Enter your details below and create your account and get started
          </p>

          <form
            className='label-input-wrapper dis-mt-40 '
            onSubmit={handleSubmit}
            action='#'
          >
            {/* <div className=""> */}
            <label
              htmlFor='email'
              className='input-label plt-ui-text-md-medium plt-font-color-primary'
            >
              Email address
            </label>
            <input
              type='email'
              id='email'
              placeholder='Enter your email'
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              className={`input-field-custom w-full ${
                emailError ? "border-red-500" : ""
              }`}
            />
            {emailError && (
              <p className='error-msg plt-body-xs'>{emailError}</p>
            )}
            {/* </div> */}
            <button
              type='submit'
              className='primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32'
            >
              {isLoading ? (
                <SyncLoader
                  color='#fff'
                  loading={true}
                  width='100%'
                  height='100%'
                />
              ) : (
                "Send link"
              )}
            </button>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default SignUpForm;
