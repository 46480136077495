import React from "react";
import { Navigate } from "react-router-dom";
import { isUserAdmin } from "helpers/auth";

const AdminRoute = ({ children }) => {
  const isAdmin = isUserAdmin();

  if (!isAdmin) {
    return <Navigate to='/login' />;
  }

  return <>{children}</>;
};

export default AdminRoute;
