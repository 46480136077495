const loadGoogleFont = (fontName) => {
  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(
    " ",
    "+"
  )}&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

const loadGoogleFonts = (fonts) => {
  var fontNames = fonts.map(
    (font) => "family=" + font.family.replace(" ", "+")
  );
  const link = document.createElement("link");
  link.href = `https://fonts.googleapis.com/css2?${fontNames.join(
    "&"
  )}&display=swap`;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

const loadManualFont_link = (font) => {
  var apiUrl = process.env.REACT_APP_SHEET_URL;
  const link = document.createElement("link");
  link.href = apiUrl + font.font_url;
  link.rel = "stylesheet";
  document.head.appendChild(link);
};

const loadManualFonts_link = (fonts) => {
  var apiUrl = process.env.REACT_APP_SHEET_URL;
  var fontNames = fonts.map((font) => {
    const link = document.createElement("link");
    link.href = apiUrl + font.font_url;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  });
};

const loadManualFont = async (font) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const fontface = new FontFace(
    font.family,
    `url("${apiUrl}/get-font/${font.url}")`,
    { weight: font.variant }
  );

  const loadedFont = await fontface.load();
  document.fonts.add(loadedFont);
};

export { loadGoogleFont, loadGoogleFonts, loadManualFont };
