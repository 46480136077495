import React, { useState } from "react";
import CommonModal from "components/Common/CommonModal";
import FormValidationError from "components/FormValidationError";

const AddPageModal = ({ isOpen, onRequestClose, onApply, title }) => {
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});

  const handleApply = () => {
    onApply(name);
    onRequestClose();
  };

  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Add'
      bodyClassName='upload-font-modal'
    >
      <div className='plt-dropdown-secondary '>
        <div className='plt-select-dropdown-wrapper dis-ml-24 dis-mr-24 dis-pt-16 dis-pb-16'>
          {/* Name field */}
          <div className='upload-font-content d-flex align-items-center justify-content-between dis-mb-16 w-100'>
            <div className='plt-select-dropdown-wrapper w-100'>
              <h6 className='plt-select-dropdown-title plt-ui-text-sm-regular plt-font-color-primary dis-mb-4'>
                Name
              </h6>
              <div className='modal-value-block w-100'>
                <input
                  className='input-field-custom modal-value-btn plt-ui-text-sm-regular plt-font-color-primary'
                  type='text'
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {errors?.name && <FormValidationError error={errors?.name} />}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default AddPageModal;
