import React from "react";
import "./IconographyBox.scss";
import { DownloadArrow } from "components/Icons";
// import AvatarImage from "../../../assets/images/png/avatar.png";
// import AvatarImage from "../../../../../assets/images/png/avatar.png";

const IconographyBox = ({ IconographyBoxData }) => {
  return (
    <div className="plt-icon-box d-flex justify-content-between align-items-center dis-p-16">
      <span className="d-block">
        <img
          src={IconographyBoxData.img}
          alt="icon"
          className="icon-image dis-mb-16"
        />
      </span>
      <div className="plt-icon-box-txt d-flex align-items-center">
        <p className="plt-badge plt-body-compact-sm">
          {IconographyBoxData.content}
        </p>
        <div className="plt-icon-box-download">
          <DownloadArrow/>
        </div>
      </div>
     
    </div>
  );
};

export default IconographyBox;
