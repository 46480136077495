import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import SideNavTabs from "../SideNavTabs";
import Header from "../Header/Header";
import "./DesignSystemLayout.scss";
import { PrimaryLogo } from "../../Icons";
import { useParams } from "react-router-dom";
import { fetchDesignSystemById } from "../../../api";
import { useGlobalContext } from "context/GlobalContext";
import { convertDefaultTokens } from "../../../helpers";
import { ToastContainer } from "react-toastify";
import { getUser } from "hooks/storageUtils";
import UserLogo from "components/user/UserLogo";
import DesignSystemLogo from "components/Common/DesignSystemLogo";
import { loadGoogleFont, loadManualFont } from "helpers/font";

const DesignSystemLayout = ({
  children,
  bannerData,
  selectedTab,
  setSelectedTab,
  tabs,
  sideTabs,
  title = "Overview",
  ...otherProps
}) => {
  const { id } = useParams();

  const { designSystemMeta, setDesignSystem } = useGlobalContext();

  const user = getUser();

  const defaultLogo = {
    component: <PrimaryLogo />,
    text: "Design",
  };

  const {
    isCollapsed,
  } = useGlobalContext();

  const designSystemLogo = {
    component: <DesignSystemLogo designSystem={designSystemMeta} />,
    text: "Design",
  };
  let defaultSideTabs = [
    { title: "Getting started", link: `/${id}/overview/getting-started` },
    // { title: "Principles", link: "/principles" },
    // { title: "Resources", link: "/resources" },
  ];
  const overviewPages =
    designSystemMeta?.pages &&
    designSystemMeta?.pages
      .filter((page) => page.type === "overview")
      .map((page) => {
        defaultSideTabs = [
          ...defaultSideTabs,
          {
            title: page.name,
            link: `/${id}/overview/pages/${page.id}`,
            page: page,
          },
        ];
      });

  const effectiveSideTabs = sideTabs ? sideTabs : defaultSideTabs;
  const [fonts, setFonts] = useState([]);

  React.useEffect(() => {
    const fetchDesignSystem = async () => {
      try {
        const data = await fetchDesignSystemById(id);
        setFonts(data.fonts);

        let tokens = convertDefaultTokens(data?.tokens);
        setDesignSystem(tokens);
      } catch (error) {
        console.error("Failed to fetch design system", error);
      }
    };

    fetchDesignSystem();
  }, []);

  useEffect(() => {
    fonts.map((font) => {
      if (font.type === "manual") {
        loadManualFont(font);
      } else {
        loadGoogleFont(font.family);
      }
    });
  }, [fonts]);

  return (
    <div className='design-system-page d-flex h-full'>
      <ToastContainer />
      <Sidebar {...otherProps} />{" "}
      <SideNavTabs
        tabs={effectiveSideTabs}
        logo={designSystemMeta?.logo ? designSystemLogo : defaultLogo}
        title={title}
      />
      <div className={`tab-content-body w-100vw ${isCollapsed ? "active" : ""}`}>
        <Header
          bannerData={bannerData}
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          title={title.toLowerCase()}
          setBannerImage={otherProps.setBannerImage}
        />
        {children}
      </div>
    </div>
  );
};

export default DesignSystemLayout;
