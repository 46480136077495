import React, { useState, useRef, useEffect } from "react";
import "./Dropdown.scss"; // Updated to include your styles

const Dropdown = ({ children, trigger, isOpen, onToggle, customClass }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onToggle(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onToggle]);

  return (
    <div className={`dropdown ${customClass}`} ref={dropdownRef}>
      <span onClick={() => onToggle(!isOpen)} className="dropdown-trigger cursor-pointer">
        {trigger}
      </span>
      {isOpen && (
        <div className="user-dropdown">
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
