const toPx = (value) => {
  return `${value}px`;
};

const shadowPreview = (selectedToken) => {
  return `${selectedToken?.value?.type?.value == "innerShadow" ? "inset" : ""
    } ${toPx(selectedToken?.value?.x)} ${toPx(selectedToken?.value?.y)} ${toPx(
      selectedToken?.value?.blur
    )} ${toPx(selectedToken?.value?.spread)}`;
};


const getScssProperty = (token) => {
  var r = document.querySelector(":root");
  var rs = getComputedStyle(r);
  return rs.getPropertyValue(token);
};
const getCssVariableValue = (variable) => {
  let cssVariable = variable.replace(/[{}]/g, "");
  cssVariable = cssVariable.replace(/\./g, "-");
  cssVariable = `--dss-${cssVariable}`;
  const element = document.documentElement;
  return getComputedStyle(element).getPropertyValue(cssVariable)?.trim();
};


function setScssProperty(token, value) {
  var r = document.querySelector(":root");
  r.style.setProperty(token, value);
}
export { shadowPreview, getScssProperty, setScssProperty, getCssVariableValue };
