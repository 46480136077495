import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./ConfirmDialog.scss";
import { ModalCloseIcon } from "components/Icons/index";
import PrimaryButton from "components/Common/PrimaryButton";

const ConfirmDialog = ({
  isOpen,
  message,
  onRequestClose,
  title,
  onApply,
  variant,
  onCancelText = "Cancel",
  onApplyText = "Delete",
  bodyClassName,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`plt-modal-overlay ${variant}`}>
      <div className='plt-modal-content' onClick={(e) => e.stopPropagation()}>
        <div className='plt-modal-header'>
          <h5 className='plt-modal-header-title plt-heading-sm plt-font-color-primary'>
            {title}
          </h5>
          <span
            className='plt-modal-close-icon plt-iconbtn'
            onClick={onRequestClose}
          >
            <ModalCloseIcon />
          </span>
        </div>
        <div className={`plt-modal-body ${bodyClassName}`}>
          <p className="delete-msg plt-body-md plt-font-color-primary dis-ml-24 dis-mr-24 dis-pt-12 dis-pb-12">{message}</p>
        </div>
        <div className='plt-modal-footer'>
          <PrimaryButton
            className={`plt-btn plt-btn-secondary plt-btn-lg plt-ui-text-md-medium `}
            text={onCancelText}
            onClick={onRequestClose}
          />
          {onApply && (
            <PrimaryButton
              className={`plt-btn plt-btn-danger plt-ui-text-md-medium `}
              text={onApplyText}
              onClick={onApply}
            />
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ConfirmDialog;
