const getUsageCount = (usages) => {
  const transform =
    usages &&
    usages.map((usage) => ({
      feature: usage.feature.name,
      used: usage.used,
    }));

  const sumByFeature =
    transform &&
    transform.reduce((acc, usage) => {
      // If the feature is not yet in the accumulator, initialize it with 0
      if (!acc[usage.feature]) {
        acc[usage.feature] = 0;
      }
      // Add the used value to the accumulator for the given feature
      acc[usage.feature] += usage.used;
      return acc;
    }, {});
  return sumByFeature;
};

const usagePercentage = (usage, plan, type) => {
  const value = (usage && usage[type]) || 0;
  const total =
    plan?.features?.find((feature) => feature.name === type)?.value || 0;
  if (value === 0 || total === 0) return 0;
  else {
    return (value / total) * 100;
  }
};

export { getUsageCount, usagePercentage };
