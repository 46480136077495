import { getUser } from "hooks/storageUtils";
import TeamTableRaw from "./TeamTableRaw";

const TeamTable = ({ data, roles, setTeams }) => {
  const user = getUser();
  const designSystems = user?.design_systems?.filter(
    (ds) => ds.admin_id === user.id
  );
  return (
    <div className='plt-table-wrapper plt-table-team'>
      {/* Team */}
      <div className='plt-table-container w-100'>
        <table className='plt-table'>
          <thead>
            <tr>
              <th className='plt-table-head'>#</th>
              <th className='plt-table-head'>Name</th>
              <th className='plt-table-head'>Role</th>
              <th className='plt-table-head'>Access</th>
              <th className='plt-table-head'>Date added</th>
              <th className='plt-table-head'>Last active</th>
              <th className='plt-table-head'></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((team, index) => (
              <TeamTableRaw
                key={team.id}
                row={team}
                slno={index + 1}
                roles={roles}
                setTeams={setTeams}
                designSystems={designSystems}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamTable;
