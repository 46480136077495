import React from "react";
import "./ToggleComponent.scss";

const ToggleComponent = ({ variant, size, checked, disabled, token, onChange }) => {
  return (
    <div className="code-box-content w-100">
      <div className="d-flex justify-content-center">
        <label
          className={`dss-toggler dss-toggler-secondary dss-toggler-${size} dss-toggler-${variant}`}
        >
          <div className="dss-toggler-elem">
            <input
              className="dss-toggler-toggler-input"
              type="checkbox"
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
            <div className="dss-toggler-slider">
              <span className={`dss-toggler-text dss-${token} dss-font-color-primary`}>
                Toggle text
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default ToggleComponent;
