import React, { useState, useEffect } from "react";
import DesignSystemLayout from "./DesignSystemLayout";
import { useGlobalContext } from "context/GlobalContext";
import { useParams } from "react-router-dom";
import CommonEditor from "components/CommonEditor";

const EditorPage = () => {
  const { pageId } = useParams();
  const { designSystemMeta } = useGlobalContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (designSystemMeta?.pages) {
      const currentPage = designSystemMeta.pages.find(
        (page) => page.id == pageId
      );
      setPage(currentPage);
    }
  }, [pageId, designSystemMeta]);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const bannerData = {
    heading: designSystemMeta?.design_system_name,
    description:
      "Foundations inform the basis of any great user interface, from accessibility standards to essential patterns for layout and interaction.",
    bannerImage: designSystemMeta.banner_url,
  };

  return (
    <DesignSystemLayout
      designSystemName={designSystemMeta?.design_system_name}
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={[page?.name]}
      title='Overview'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && page && <CommonEditor page={page} />}
      </div>
    </DesignSystemLayout>
  );
};

export default EditorPage;
