import React, { useEffect, useState } from "react";
import CommonModal from "components/Common/CommonModal";
import './VerificationModal.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtp, forgetPassword } from "api";
import { SyncLoader } from "react-spinners";
import OtpInput from "components/OtpInput/OTPInput";



const VerificationModal = ({ isOpen, onRequestClose, onApply, title }) => {


  const [timer, setTimer] = useState(119);
  const [canResend, setCanResend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      setOtp(token.split(""));
    }

    const intervalId = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalId);
          setCanResend(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [token]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleContinue = async () => {
    const request = {
      email: email,
      token: otp.join(""),
    };

    try {
      const data = await verifyOtp(request);
      navigate(`/reset?email=${email}&token=${otp.join("")}`);
    } catch (error) {
      console.error("OTP verification failed", error.message);
      // Show error message
    }
  };

  const handleResend = async (event) => {
    event.preventDefault();
    try {
      const response = await forgetPassword(email);
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message);
    }
  };
  // const handleApply = () => {
  //   onApply(file);
  // };


  return (
    <CommonModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      // onApply={handleApply}
      onCancelText='Cancel'
      onApplyText='Continue'
    // bodyClassName='change-pswd-modal'
    >
      <div className='plt-modal-body-content'>
        <div className="plt-verification-otp-wrapper dis-mt-12 dis-mb-12">
          <div className="plt-verify-otp-container flex flex-column w-full">
            {/* <ToastContainer /> */}

            {/* <h4 className="plt-heading-lg plt-font-color-primary dis-px-auto title">
          Password reset
        </h4> */}
            <p className="plt-verify-otp-description plt-body-sm dis-mb-8 d-flex">
              Enter OTP sent to
              <span className="plt-verify-otp-desc plt-body-sm dis-ml-4">
                jenna123@gmail.com
              </span>
            </p>


            <OtpInput value={otp} onChange={setOtp} />

            {/* <button
          type="submit"
          className="primary-button-custom plt-ui-text-lg-medium plt-btn plt-btn-primary dis-mt-32"
          onClick={handleContinue}
        >
          {isLoading ? (
            <SyncLoader color="#fff" loading={true} width="100%" height="100%" />
          ) : (
            "Continue"
          )}

        </button> */}

            <div className="resend plt-body-sm dis-mt-12">
              Didn't receive an OTP?
              {canResend ? (
                <span
                  className="plt-modal-link plt-body-sm cursor-pointer font-weight-500 dis-ml-4"
                  onClick={handleResend}
                >
                  Resend OTP
                </span>
              ) : (
                <span className="dis-ml-8">Resend in {formatTime()}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>

  );
};

export default VerificationModal;
