import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./ColorToken.scss";
import TokenTable from "./TokenTable";

const ColorToken = ({ TokenColorDatas, sectionRefs, isLoading }) => {
  const headers = ["Token name", "Description", "Value"];

  return (
    <div className='tab-content-container dis-pr-48 dis-pl-48'>
      {isLoading ? (
        <Skeleton height={40} count={10} />
      ) : (
        <>
          <div className='plt-content-block'>
            <div className='plt-content-block-top d-flex align-items-center dis-mb-8'>
              <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                Tokens
              </h6>
            </div>
            <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
              Each token represents a specific aspect of the design, such as
              color, typography, spacing, or components. By defining and
              organizing these tokens, design systems ensure that designers and
              developers can easily maintain a cohesive visual language and make
              efficient, system-wide updates.
            </p>
          </div>
          {TokenColorDatas.map((data, index) => (
            <div
              ref={sectionRefs[index]}
              key={data.id}
              className='plt-content-block dis-mb-0'
            >
              <div className='plt-content-wrapper dis-mb-48'>
                {/* <div className='plt-content-block-top d-flex justify-content-between align-items-center dis-mb-8'>
                  <h6 className='plt-contents-head plt-heading-md plt-font-color-primary'>
                    {data.title}
                  </h6>
                </div> */}
                {/* <p className='plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400'>
                  {data.description}
                </p> */}
              </div>
              <h5 className='plt-sub-heading plt-heading-md plt-font-color-primary dis-pb-8'>
                {data.sub_heading}
              </h5>
              <TokenTable
                data={data.colorData}
                headers={headers}
                type={data.type}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ColorToken;
