import React from "react";
import "./PrimaryButton.scss";
import { ButtonIcon } from "../../Icons";

function PrimaryButton({
  text,
  onClick,
  className,
  prefixIcon,
  suffixIcon,
  prefixClass,
  suffixClass,
  disabled = false,
  style,
}) {
  return (
    <button
      className={className}
      type="button"
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {prefixIcon && (
        <span className={`dss-icon prefix-icon ${prefixClass}`}>
          {prefixIcon == true ? <ButtonIcon color="#FFFFFF" /> : prefixIcon}
        </span>
      )}
      {text}
      {suffixIcon && (
        <span className={`dss-icon suffix-icon ${suffixClass}`}>
          {suffixIcon == true ? <ButtonIcon color="#FFFFFF" /> : suffixIcon}
        </span>
      )}{" "}
    </button>
  );
}

export default PrimaryButton;
