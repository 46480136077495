import React, { useRef, useState } from "react";
import "./Textarea.scss";
import { ErrorIcon } from "../Icons";
import Tag from "components/Tag";

const Textarea = ({
  placeholder = "value",
  denseClassName,
  readOnlyState,
  disabled,
  value = "",
  readOnly,
  validation,
  id,
  fluid,
  tag = false
}) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState(tag ? [value] : []);
  const [isFocus, setFocus] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const maxCount = 20;

  const handleFocus = () => {
    if (!disabled & !tag) {
      setFocus(true);
      inputRef?.current?.focus();
    }
  };

  const handleBlur = () => {
    setFocus(false);
    if (validation && tags.length === 0 && !inputValue) {
      setError("Helpful validation message");
    } else if (validation) {
      validateInput(inputValue);
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (newValue.length <= maxCount) {
      setInputValue(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === " ") && inputValue.trim()) {
      e.preventDefault();
      addTag(inputValue.trim());
    }
  };

  const addTag = (newTag) => {
    setTags((prevTags) => [...prevTags, newTag]);
    setInputValue("");
  };

  const removeTag = (index) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  const validateInput = (value) => {
    if (value.length < 5) {
      setError("Helpful validation message");
    } else {
      setError(null);
    }
  };

  return (
    <div
      className={`dss-textarea-wrapper dss-textarea ${denseClassName} ${disabled ? "disabled" : ""
        } ${readOnlyState ? "read-only" : ""} ${fluid ? "dss-textarea-fluid" : ""}`}
    >
      {!fluid && (
        <label htmlFor={id} className="dss-textarea-label">
          Label
        </label>
      )}
      <div
        onClick={handleFocus}
        className={`dss-textarea-container ${inputValue?.length ? 'has-value' : ''}  ${isFocus ? "focused" : ""} ${error ? "error" : ""
          }`}
      >
        {fluid && (
          <label htmlFor="textarea" className="dss-textarea-label">
            Label
          </label>
        )}
        <div className="textarea-tag-container">
          {tag ? tags.map((t, index) => (
            <Tag
              key={index}
              size="small"
              typo="ui-text-xs-medium"
              prefixIcon={true}
              statusIcon={false}
              mode={"closable"}
              onClose={() => removeTag(index)}
            >
              {t}
            </Tag>
          )) : <textarea
            ref={inputRef}
            readOnly={readOnly}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={tag ? handleKeyDown : null}
            value={inputValue}
            disabled={disabled}
            // placeholder={tags.length === 0 ? placeholder : ""}
            placeholder={fluid ? '' : placeholder}
            id={id}
            className="dss-textarea-field"
          />}

        </div>
      </div>
      {!validation && (
        <div className="dss-count-container">
          <h6 className="dss-count-text">Helper text</h6>
          <h6 className="dss-count-text">
            {tag ? `${tags.length}/${maxCount}` : `${value.length}/${maxCount}`}
          </h6>
        </div>
      )}
      <div className={`dss-error-container ${error ? "active" : ""}`}>
        <span className="dss-error-icon">
          <ErrorIcon />
        </span>
        <h6 className="dss-error-message">
          {error ? error : "Helpful validation message"}
        </h6>
      </div>
    </div>
  );
};

export default Textarea;
