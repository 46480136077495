import React from "react";
import { hexToRgb, rgbaToHex } from "helpers/color";

const ColorColumn = ({ rowData }) => (
  <>
    <span className='plt-color-text plt-body-compact-sm'>{rowData.value}</span>
    {!rowData.value.includes("rgba(") && (
      <span className='plt-color-text plt-body-compact-sm'>
        {hexToRgb(rowData.value)}
      </span>
    )}
    {rowData.value.includes("rgba(") && (
      <span className='plt-color-text plt-body-compact-sm'>
        {rgbaToHex(rowData.value)}
      </span>
    )}
  </>
);

export default ColorColumn;
