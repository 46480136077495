import { useCallback } from "react";

const useColorConversion = () => {
  const rgbaToHex = (rgba) => {
    let r, g, b, a;

    // Handling RGBA objects directly
    if (
      typeof rgba === "object" &&
      rgba !== null &&
      "r" in rgba &&
      "g" in rgba &&
      "b" in rgba
    ) {
      r = rgba.r;
      g = rgba.g;
      b = rgba.b;
      a = rgba.hasOwnProperty("a") ? rgba.a : 1; // Default alpha to 1 if not specified
    } else if (typeof rgba === "string") {
      const parts = rgba.match(
        /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/
      );
      if (!parts) {
        throw new Error("The provided string is not a valid RGBA color value.");
      }
      [r, g, b, a] = parts.slice(1).map((num) => parseFloat(num));
    } else {
      throw new Error("Input must be an RGBA string or RGBA object.");
    }

    // Convert each component to hex and pad with zeros if necessary
    let hexR = r.toString(16).padStart(2, "0");
    let hexG = g.toString(16).padStart(2, "0");
    let hexB = b.toString(16).padStart(2, "0");
    let hexA = Math.round(a * 255)
      .toString(16)
      .padStart(2, "0");

    return `#${hexR}${hexG}${hexB}${hexA}`;
  };

  const hexToRgba = useCallback((hex) => {
    const hexCode = hex.replace("#", "");
    const r = parseInt(hexCode.substring(0, 2), 16);
    const g = parseInt(hexCode.substring(2, 4), 16);
    const b = parseInt(hexCode.substring(4, 6), 16);
    const a =
      hexCode.length === 8
        ? (parseInt(hexCode.substring(6, 8), 16) / 255).toFixed(3)
        : 1;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }, []);

  return { rgbaToHex, hexToRgba };
};

export default useColorConversion;
