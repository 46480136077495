import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

async function login(email, password) {
  try {
    const response = await axios.post(
      `${apiUrl}/login?email=${email}&password=${password}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function register(
  name,
  email,
  password,
  password_confirmation,
  companyname
) {
  try {
    const response = await axios.post(`${apiUrl}/register`, {
      name,
      email,
      password,
      password_confirmation,
      companyname,
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

// Improved fetchUserData function using axios (replace with your actual implementation)
const fetchUserData = async (token) => {
  try {
    const response = await axios.get(`${apiUrl}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ContentType: "application/json", // Corrected content-type header
      },
    });


    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};


async function inviteRegister(
  name,
  email,
  password,
  password_confirmation,
  invite_token
) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/invite-register`,
      {
        name,
        email,
        password,
        password_confirmation,
        invite_token,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

async function forgetPassword(email) {
  try {
    const response = await axios.post(
      `${apiUrl}/forgetpassword?email=${email}`
    );
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

//Fetch description or page data
async function fetchPasswordAudit() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/password-audit`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch password audit:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function updateUser(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in update user:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function fetchUserSubscription() {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${apiUrl}/subscriptions`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in fetch user subscription:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function changePassword(request) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/change-password`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

async function verifyOtp(request) {
  try {
    const response = await axios.post(`${apiUrl}/varify-otp`, request);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

async function resetPassword(request) {
  try {
    const response = await axios.post(`${apiUrl}/resetpassword`, request);
    const data = response.data;
    return data;
  } catch (error) {
    throw new Error(
      error.response
        ? error.response.data.errors
        : "An unexpected error occurred"
    );
  }
}

// Upload avatar
async function uploadAvatar(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in upload avatar:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Delete user
async function deleteUser(id) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`${apiUrl}/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in delete user:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Upload avatar
async function updateGeneralSettings(data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/settings/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in update general setting:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

// Upload avatar
async function switchDomain(domainId) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${apiUrl}/switch-domain/${domainId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error in switch domain:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export {
  login,
  register,
  inviteRegister,
  forgetPassword,
  fetchPasswordAudit,
  updateUser,
  fetchUserSubscription,
  changePassword,
  verifyOtp,
  resetPassword,
  uploadAvatar,
  deleteUser,
  updateGeneralSettings,
  switchDomain, fetchUserData
};
