import React, { useState } from "react";
import FormValidationError from "components/FormValidationError";

const FileInput = ({ accept, maxSize, onFileChange, error }) => {
  const [localError, setLocalError] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > maxSize) {
        // File size validation
        setLocalError(`File size should be less than ${maxSize / 1024 / 1024}MB.`);
        onFileChange(null);
      } else {
        setLocalError(null);
        onFileChange(selectedFile);
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        id="file-input"
        accept={accept}
        onChange={handleFileChange}
        className="input-field-custom"
      />
      {(localError || error) && <FormValidationError error={localError || error} />}
    </div>
  );
};

export default FileInput;
