import React, { useState } from "react";
import "./PreviewAvatarComponent.scss";
import { AvatarDatas } from "../../../../assets/data/data";
import TabNavigation from "../../TabNavigation";
import ComponentCard from "../../../Common/ComponentCard";
import AvatarComponent from "../../../Common/AvatarComponent";
import CommonModal from "../../../Common/CommonModal";
import CustomSelect from "../../../Common/CustomSelect";
import EditButton from "../../../Buttons/EditButton";
import { EditIcon } from "components/Icons";

const PreviewAvatarComponent = ({ tabData, handleTabClick, activeTab, setSelectedTab }) => {
  const avatarComp = [
    { title: "Double Extra Large", size: "xxl" },
    { title: "Extra large", size: "xl" },
    { title: "Large", size: "lg" },
    { title: "Normal" },
    { title: "Dense", size: "sm" },
    { title: "Denser", size: "xs" },
  ];

  const [isModalVisible, setModalVisibility] = useState(false);
  const [buttonState, setButtonState] = useState("Default");

  const showModal = () => {
    setModalVisibility(true);
  };

  const AllUsage = () => (
    <p className="plt-character-des plt-body-compact-sm dis-pt-12 dis-mb-32">
      See all states{" "}
      <span
        onClick={() => setSelectedTab(1)}
        className="plt-text-underline plt-body-compact-sm plt-font-color-primary font-weight-500 cursor-pointer"
      >
        usage
      </span>
    </p>
  );

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Default
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data) => (
          <div key={data.id} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <div className="plt-avatar-set">
                <AvatarComponent size="xl" state={data.state} />
              </div>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Silhouette
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data) => (
          <div key={data.id} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <div className="plt-avatar-set">
                <AvatarComponent size="xl" state={data.state} content="icon" />
              </div>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Text
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {AvatarDatas.map((data) => (
          <div key={data.id} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <div className="plt-avatar-set">
                <AvatarComponent
                  size="xl"
                  content="text"
                  text="AA"
                  state={data.state}
                />
              </div>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
        <div className="plt-content-wrapper dis-mb-24">
          <h6 className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8">
            Sizes
          </h6>
          <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Use a primary button to call attention to an action on a form or to
            highlight the strongest call to action on a page. Primary buttons
            should only appear once per container (not including the application
            header or in a modal dialog). Not every screen requires a primary
            button.
          </p>
        </div>
        {avatarComp.map((data) => (
          <div key={data.size} className="dis-mb-32">
            <div className="plt-content-wrapper dis-mb-24">
              <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                {data.title}
              </h6>
              <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                {data.description}
              </p>
            </div>
            <ComponentCard editClick={showModal}>
              <div className="plt-avatar-set">
                <AvatarComponent size={data.size} state={data.state} />
                <AvatarComponent
                  size={data.size}
                  state={data.state}
                  content="icon"
                />
                <AvatarComponent
                  size={data.size}
                  content="text"
                  text="AA"
                  state={data.state}
                />
              </div>
            </ComponentCard>
            <AllUsage />
          </div>
        ))}
      </div>

      <CommonModal
        isOpen={isModalVisible}
        onRequestClose={() => setModalVisibility(false)}
        title="Tokens"
        onApply={() => setModalVisibility(false)}
        onCancelText="Cancel"
        onApplyText="Apply"
        variant="modal-tertiary"
      >
        <div className="plt-modal-body-content">
          <div className="plt-dropdown-secondary">
            <div className="plt-select-dropdown-wrapper d-flex align-items-center justify-content-between">
              <div className="plt-dropdown-wrapper plt-dropdown-wrapper-common">
                <CustomSelect
                  options={["Default", "Hovered", "Pressed", "Disabled"]}
                  selectedOption={buttonState}
                  onChange={setButtonState}
                />
              </div>
            </div>
          </div>

          <div className="plt-preview plt-heading-xl dis-mb-32">
            <div className="plt-avatar-set">
              <AvatarComponent size="xl" state={buttonState} />
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Padding
            </h6>
            <div className="plt-token-value-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">H:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>
              <div className="d-flex align-items-center">
                <span className="dis-mr-4">V:</span>
                <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                  spacing-200
                </p>
              </div>

              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>

          <div className="plt-token-details dis-mb-32">
            <h6 className="plt-token-title plt-heading-xs plt-font-color-primary dis-mb-4">
              Radius
            </h6>
            <div className="plt-token-value-item d-flex">
              <p className="plt-badge plt-token-value-name plt-body-compact-sm dis-mr-12">
                radius-150
              </p>
              <span className="plt-iconbtn plt-iconbtn-sm plt-token-value-icon">
                <EditIcon />
              </span>
            </div>
          </div>
        </div>
      </CommonModal>

      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default PreviewAvatarComponent;
