import React, { useState } from "react";
import { useGlobalContext } from "../../../../context/GlobalContext";
import TabNavigation from "../../TabNavigation";
import tableDetail from "../../../../assets/images/png/tableDetail.png";
import tabledefault from "../../../../assets/images/png/tabledefault.png";
import tablehover from "../../../../assets/images/png/tablehover.png";
import tablePlacement from "../../../../assets/images/png/tablePlacement.png";
import GuidelineCardTable from "../../../Common/GuidelineCardTable";
import useScrollManagement from "hooks/useScrollManagement";
import DetailItem from "components/DetailItem";

const details = [
  { number: 1, title: 'Title and description', description: 'Contains the data table title and an optional description.' },
  { number: 2, title: 'Column header', description: ' title for the row header with the optional sorting behavior.' },
  { number: 3, title: 'Pagination table bar', description: 'An optional component that lets a user navigate data as pages when the amount of data it too large to be shown at once.' },

];

const UsageTableComponent = () => {
  const { isLoading } = useGlobalContext();
  const tabData = ["Anatomy", "States of Table", "Placement", "Guidelines"];

  const [activeSideTab, setActiveSideTab] = useState(0);

  const sectionRefs = React.useRef(tabData.map(() => React.createRef()));

  const scrollToSection = useScrollManagement(
    sectionRefs.current,
    activeSideTab,
    setActiveSideTab,
    "tab-content-body"
  );

  const handleTabClick = (index) => {
    setActiveSideTab(index);
    scrollToSection(index);
  };

  return (
    <div className="tab-content-wrapper d-flex justify-content-between">
      <div className="tab-content-container dis-pr-48 dis-pl-48">
        <div className="plt-content-block">
          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[0]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-8"
            >
              Anatomy
            </h6>
            <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
            Table’s features are ideal for organizing and displaying data in a UI. The column headers can sort data in ascending or descending order, rows can be expanded to progressively disclose information, and single or batch actions can be taken on rows.
            </p>
          </div>
          <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={tableDetail} alt="userimage" />
          </div>

          <ul className="plt-btn-detail-set dis-mb-40 dis-mt-24">
            {details.map(detail => (
              <DetailItem  
                key={detail.number} 
                number={detail.number} 
                title={detail.title} 
                description={detail.description} 
              />
            ))}
          </ul>

          <div className="plt-content-wrapper dis-mb-24">
            <h6
              ref={sectionRefs.current[1]}
              className="plt-contents-head plt-heading-md plt-font-color-primary dis-mb-24"
            >
              States of table
            </h6>
            <div className="plt-btn-box-wrapper d-flex justify-content-between flex-wrap">
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                  Default
                  </h4>
                  <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                    <img src={tabledefault} alt="userimage" />
                  </div>
                </div>
                <div className="plt-btn-box">
                  <h4 className="plt-character-title plt-font-color-primary plt-heading-xs dis-mb-8">
                  Hover
                  </h4>
                  <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                    <img src={tablehover} alt="userimage" />
                  </div>
                </div>
             
              </div>
          </div>

          {/* <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
            <img src={CheckboxAlignment} alt="userimage" />
          </div> */}


            <div className="dis-mt-40 dis-mb-40">
              <div className="plt-content-wrapper dis-mb-24">
                <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
                Placement
                </h6>
                <p className="plt-contents-desc plt-body-md plt-font-color-secondary font-weight-400">
                Tables should be placed in a page’s main content area and given plenty of space to display data without truncation. Avoid placing tables inside tables or smaller containers where the information can feel cramped or needs truncation.
                </p>
              </div>
              
              <div className="plt-btn-detail-wrapper d-flex align-items-center justify-content-center">
                <img src={tablePlacement} alt="userimage" />
              </div>

            </div>
   

          <div className="plt-content-wrapper dis-mb-12">
            <h6 className="plt-contents-head plt-heading-sm plt-font-color-primary dis-mb-8">
            Guidelines
            </h6>
           
          </div>

          <GuidelineCardTable />
        </div>
      </div>
      <div className="plt-token-contents">
        <TabNavigation
          tabs={tabData}
          title={"CONTENTS"}
          activeIndex={activeSideTab}
          onTabClick={handleTabClick}
        />
      </div>
    </div>
  );
};

export default UsageTableComponent;
