import React from "react";
import tableguidelines1 from "../../../assets/images/png/tableguidelines1.png";
import tableguidelines2 from "../../../assets/images/png/tableguidelines2.png";


const ComponentPrimaryDatas = [
  {
    id: 1,
    title: "Do",
    titleClassname: "active",
    image: tableguidelines1,
    description:
      "Do use the same row height for the table and header rows.",
  },
  {
    id: 2,
    title: "Don’t",
    titleClassname: "inactive",
    image: tableguidelines2,
    description:
      "Don’t make header rows too small",
  },

];

const GuidelineCardTable = () => {
  return (
    <div className='guideline-card d-flex justify-content-between'>
      {ComponentPrimaryDatas.map((data) => (
        <div key={data.id} className='guideline-wrapper'>
          <h6
            className={`guideline-badge plt-body-compact-md dis-mb-8 ${data.titleClassname}`}
          >
            {data.title}
          </h6>
          <div className='guideline-detail-wrapper d-flex align-items-center justify-content-center'>
            <div className='guideline-img'>
              <img src={data.image} alt='userimage' />
            </div>
          </div>
          <p className='guideline-desc plt-body-md dis-mt-12'>
            {data.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default GuidelineCardTable;
