import React, { useState } from "react";
import { useParams } from "react-router-dom";

import DesignSystemLayout from "components/DesignSystemPage/DesignSystemLayout";
import { getStylesSideTabData } from "assets/data/sideTabData";
import IconographyStyles from "components/DesignSystemPage/Iconography/IconographyStyles";
import { useGlobalContext } from "context/GlobalContext";
import UsageEditor from "components/DesignSystemPage/UsageEditor";

const DesignSystemIconography = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleHeaderTabClick = (id) => {
    setSelectedTab(id);
  };

  const bannerData = {
    heading: "Iconography",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage"];
  return (
    <DesignSystemLayout
      selectedTab={selectedTab}
      setSelectedTab={handleHeaderTabClick}
      bannerData={bannerData}
      tabs={tabs}
      sideTabs={getStylesSideTabData(id)}
      title='Foundation'
    >
      <div className='tab-content-wrapper d-flex justify-content-between'>
        {selectedTab === 0 && <IconographyStyles />}
        {selectedTab === 1 && <UsageEditor pageType={"ic_usage"} />}
      </div>
    </DesignSystemLayout>
  );
};

export default DesignSystemIconography;
