import React from "react";
import { ErrorIcon } from "./Icons";

const FormValidationError = ({ error }) => {
  return (
    <div className={`dss-error-container ${error && "active"}`}>
      <span className='dss-error-icon'>
        <ErrorIcon />
      </span>
      <h6 className='dss-error-message'>{error}</h6>
    </div>
  );
};

export default FormValidationError;
