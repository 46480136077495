import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

//Font create
async function createFont(data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/fonts`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in creating font:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}
// Upload font
async function uploadFont(id, data) {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${apiUrl}/upload-font/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error in upload font:", error);
    throw new Error(
      error.response
        ? error.response.data.message
        : "An unexpected error occurred"
    );
  }
}

export { createFont, uploadFont };
