import React, { useState, useEffect } from "react";
import Select from "react-select";

const SelectInput = ({ options, onSelect, defaultValue, multi = false }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (option) => {
    setSelectedValue(option);
    onSelect(option);
  };

  const customStyles = {
    indicatorSeparator: () => ({ display: "none" }), // removes the "stick"
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      "& svg": { display: "none" },
    }),
    control: (base, state) => ({
      ...base,
      background: "#fff",
      border: state.menuIsOpen ? "1px solid #A6A6A6" : "1px solid #F0F0F0",
      boxShadow: "none",
      "&:hover": {
        border: state.menuIsOpen ? "1px solid #A6A6A6" : "1px solid #A6A6A6",
      },
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "14px",
        right: "14px",
        width: "1.5px",
        height: "7px",
        borderRadius: "1px",
        background: state.menuIsOpen ? "#171717" : "#171717",
        transform: state.menuIsOpen ? "rotate(-45deg)" : "rotate(-135deg)",
        transition: "All .3s",
        zIndex: "1",
      },
      "&:after": {
        right: "18px",
        transform: state.menuIsOpen ? "rotate(45deg)" : "rotate(135deg)",
      },
    }),
    option: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      color: "#171717",
      fontSize: '14px',
      padding: "12px 16px",
      // 'min-width': "155px",
      "&:hover": {
        backgroundColor: "#F6F6F6",
      },
    }),
  };

  return (
    <Select
      styles={customStyles}
      value={selectedValue}
      onChange={handleChange}
      options={options}
      isMulti={multi}
    />
  );
};

export default SelectInput;
