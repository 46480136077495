import React from "react";
import "./AddButton.scss";
import { AddButtonicon } from "../../Icons";

const AddButton = () => {
  return (
    <button className="plt-iconbtn add-btn">
      <span className="plt-btn-icon">
        <AddButtonicon />
      </span>
    </button>
  );
};

export default AddButton;
