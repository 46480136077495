import React, { useRef, useState } from "react";
import "./ScaleTable.scss";
import EditButton from "../../../../Buttons/EditButton";
import CopyButton from "../../../../Buttons/CopyButton";
import Tooltip from "components/Tooltip";
import AuthAction from "components/auth/AuthAction";

const ScaleTable = ({
  rows,
  type,
  openModal,
  setSelectedToken,
  setSelectedRef,
}) => {
  // const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const headingRefs = rows?.map(() => useRef(null));
  return (
    <div className='plt-table-wrapper'>
      <div className='plt-scale-table-container'>
        <table className='plt-scale-table plt-heading-table'>
          <thead>
            <tr>
              <th className='plt-scale-table-header'>Preset scales</th>
              <th className='plt-scale-table-header'>Set name</th>
              <th className='plt-scale-table-header'>Usage</th>
              <th className='plt-scale-table-header'>Values</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>
                  <h3
                    ref={headingRefs[index]}
                    className={` plt-heading-text`}
                    style={{
                      fontFamily: row?.fontFamily?.value,
                      fontSize: row?.fontSize?.value,
                      lineHeight: row?.lineHeight?.value,
                      fontWeight: row?.fontWeight?.value,
                    }}
                  >
                    Heading over two or three lines
                  </h3>
                </td>
                <td>
                  <div className='plt-badge-icon-wrapper d-flex align-items-center'>
                    <p className='plt-badge plt-body-compact-sm'>{`${row?.className}`}</p>
                    <div className='plt-table-hover-icon dis-ml-8'>
                      {/* {hoveredRowIndex === index && (
                        <div className="copy-icon">
                          <CopyButton />
                        </div>
                      )} */}
                      <div className='copy-icon'>
                        <CopyButton text={row?.className} />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <p className='plt-heading-usage'>{row?.usage}</p>
                </td>
                <td>
                  <div className='d-flex plt-heading-values-wrapper'>
                    <p className='plt-heading-values'>
                      <span className='plt-heading-info'>{`Type: ${row?.fontFamily?.value}`}</span>
                      <span className='plt-heading-info'>{`Size: ${row?.fontSize?.value}`}</span>
                      <span className='plt-heading-info'>{`Line height: ${row?.lineHeight?.value}`}</span>
                      <span className='plt-heading-info'>{`Weight: ${row?.fontWeight?.value}`}</span>
                      {/* <span className='plt-heading-info'>{`Letter spacing: ${row?.letterSpacing?.value}`}</span> */}
                    </p>
                    <div className='plt-table-hover-icon dis-ml-8'>
                      {/* {hoveredRowIndex === index && (
                        <span onClick={() => openModal()}>
                          <EditButton />
                        </span>
                      )} */}
                      <AuthAction>
                        <div
                          onClick={() => {
                            openModal();
                            setSelectedToken(row);
                            setSelectedRef(headingRefs[index]);
                          }}
                        >
                          <Tooltip
                            position='top'
                            arrow='btm-center'
                            text='Edit typography'
                          >
                            <EditButton />
                          </Tooltip>
                        </div>
                      </AuthAction>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScaleTable;
