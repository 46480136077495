import React from "react";
import { UploadIcon } from "../../Icons";

const UploadButton = () => {
  return (
    <button className='plt-iconbtn edit-btn'>
      <span className='plt-btn-icon'>
        <UploadIcon />
      </span>
    </button>
  );
};

export default UploadButton;
