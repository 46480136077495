import React, { useState } from "react";
import "./Tabs.scss";

const Tabs = ({ dataItem, variant }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="dss-tab-wrapper d-flex flex-column">
      <div className="dss-tab">
        <div className={`dss-tab-header dss-tab-header-${variant}`}>
          {dataItem?.map((item) => {
            return (
              <button
                className={`dss-tab-btn dss-ui-text-sm-semi-bold ${
                  activeTab === item.key - 1 ? "active" : ""
                } dss-tab-btn-${variant}`}
                onClick={() => {
                  setActiveTab(item.key - 1);
                }}
              >
                {item.prefix && (
                  <span className="prefix-icon">{item.prefix}</span>
                )}
                {item.label}
                {item.badge && (
                  <span className="dss-tab-btn-badge">{item.badge}</span>
                )}
                {item.suffix && (
                  <span className="suffix-icon">{item.suffix}</span>
                )}
              </button>
            );
          })}
        </div>
        <div className="dss-tab-body">{dataItem[activeTab].content}</div>
      </div>
    </div>
  );
};

export default Tabs;
