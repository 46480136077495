import React from "react";

const TypographyColumn = ({ rowData }) => {
  return (
    <>
      <span className='plt-color-text plt-body-compact-sm'>
        Type: {rowData.value.fontFamily.value}
      </span>
      <span className='plt-color-text plt-body-compact-sm'>
        Size: {rowData.value.fontWeight.value}
      </span>
      <span className='plt-color-text plt-body-compact-sm'>
        Line height: {rowData.value.lineHeight.value}
      </span>
      <span className='plt-color-text plt-body-compact-sm'>
        Weight: {rowData.value.fontWeight.value}
      </span>
    </>
  );
};

export default TypographyColumn;
