import React from "react";

const Permission = ({ designSystem, children }) => {
  const userAccess = designSystem?.users?.find((user) => user.id === user.id);
  return (
    <>{userAccess && userAccess.pivot.access_id !== 1 ? null : children}</>
  );
};

export default Permission;
