import React, { useState } from "react";
import "../../assets/scss/pages/_DesignSystemComponents.scss";
import DesignSystemLayout from "../../components/DesignSystemPage/DesignSystemLayout";
import { useParams } from "react-router-dom";
import { getComponentsSideData } from "../../assets/data/sideTabData";
// import UsageButtonComponent from "../../components/DesignSystemPage/ButtonComponent/UsageButtonComponent";
// import TokenButtonComponent from "../../components/DesignSystemPage/ButtonComponent/TokenButtonComponent";
// import PreviewButtonComponent from "../../components/DesignSystemPage/ButtonComponent/PreviewButtonComponent";
import PreviewRadioComponent from "../../components/DesignSystemPage/RadioComponent/PreviewRadioComponent";
import { useGlobalContext } from "context/GlobalContext";
import UsageRadioComponent from "components/DesignSystemPage/RadioComponent/UsageRadioComponent";

const RadioComponents = () => {
  const { id } = useParams();
  const { designSystemMeta } = useGlobalContext();

  const tabData = [
    "Default",
    "Outline",

  ];
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const bannerData = {
    heading: "Radio Button",
    description:
      "Add, replace, generate colors which makes your design looks awesome!",
    bannerImage: designSystemMeta.banner_url,
  };
  const tabs = ["Preview", "Usage", "Tokens"];

  const renderContent = () => {
    switch (selectedTab) {
      case 0:
        return (
          <PreviewRadioComponent
            tabData={tabData}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            setSelectedTab={setSelectedTab}
          />
        );
      case 1:
        return (
          <div>
            <UsageRadioComponent/>
          </div>
        );
      case 2:
        return <div>content3</div>;
      default:
        return null;
    }
  };
  return (
    <DesignSystemLayout
      bannerData={bannerData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      sideTabs={getComponentsSideData(id)}
      title='Components'
    >
      {renderContent()}
    </DesignSystemLayout>
  );
};

export default RadioComponents;
