import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import RouteLoader from "../RouteLoader";
import { fetchUserData } from "api/auth";  // Import your actual fetch function

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Set initial state to null
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userId = queryParams.get("userId");
  // const user = queryParams.get("user");

  useEffect(() => {
    // Check URL params first
    // const user = queryParams.get("user");
    const token = queryParams.get("token");
    const userId = queryParams.get("userId");

    if (token && userId) {
      localStorage.setItem("token", token);

      try {
        // const parsedUser = JSON.parse(decodeURIComponent(user));
        // localStorage.setItem("user", JSON.stringify(parsedUser));

        // Rest of the logic to perform API request and update isAuthenticated
        fetchUserData(token).then(response => {
          if (response) {
            localStorage.setItem("user", JSON.stringify(response.data));
            setIsAuthenticated(true);
            // Clean URL after storing user and token
            navigate(location.pathname, { replace: true });
          } else {
            setIsAuthenticated(false);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error("Error in authentication:", error);
        setIsAuthenticated(false);
        setLoading(false);
      }
    } else {
      // Check local storage if URL params are missing
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setIsAuthenticated(true);
        setLoading(false);
        return; // Skip further processing if token exists in localStorage
      }
      setIsAuthenticated(false);
      setLoading(false);
    }

    // Update dependencies to trigger on changes in URL params or token
  }, [token, userId, location.pathname, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Optionally use a loading spinner here
  }

  if (!isAuthenticated && !loading) { // Check both isAuthenticated and loading
    return <Navigate to="/login" />;
  }

  return <RouteLoader>{children}</RouteLoader>;
};

export default ProtectedRoute;